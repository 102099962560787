import React, { Component, Fragment, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routing/Router';

// REDUX
import { Provider } from 'react-redux';
import store from './store.js';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './redux/actions/auth';

const { Offline, Online } = require("react-detect-offline");

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (
      <Provider store={store}>
          <Online>
              <BrowserRouter>
                  <Fragment>
                      <div>
                          <Router/>
                      </div>
                  </Fragment>
              </BrowserRouter>
          </Online>
        <Offline>
          <div className="text-center">
            <br/><br/><br/>
            <svg className="no-connection-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1143.01 279.52" >
            <g id="Layer_2" data-name="Layer 2">
            <path className="cls-1"
                  d="M1160.47,428.08c-4.17,3.43-11.28,5-16.13,5-6.84,0-10.8-2-10.8-12.4V364.79h33.25v-37.6h-33.25V288.7h-17l-.49,3.44c-3.26,23-16.89,41.7-38.38,52.56l-2.19,1.11v19h17.31v53.62c0,18.26,3.18,30.64,10,38.95,7.25,8.81,18.84,13.1,35.42,13.1,10.35,0,20.19-2.57,27-7l1.8-1.19V422.67Z"
                  transform="translate(-24 -259.34)"/>
            <path className="cls-1"
                  d="M1054.73,344.39A75.29,75.29,0,0,0,1002.8,324c-40.88,0-74.14,32.85-74.14,73.23a72.31,72.31,0,0,0,21.6,51.8A74.46,74.46,0,0,0,1003,470.46c21.34,0,38.87-6,56.82-19.48l3.69-2.77-26.64-26.64-2.82,2.5c-7.12,6.3-18.86,10.38-29.91,10.38-13.86,0-25.25-7.29-31.39-19.79H1075.5l.49-3.43a101,101,0,0,0,1.18-14.23A71.48,71.48,0,0,0,1054.73,344.39Zm-82.06,35.86c5.77-12.18,17-19.78,29.9-19.78s24.51,7.75,30.33,19.78Z"
                  transform="translate(-24 -259.34)"/>
            <path className="cls-1"
                  d="M869.59,324c-16.83,0-30.38,4.77-43.45,15.37-10-9.68-23.37-15.37-36.48-15.37a49.53,49.53,0,0,0-27.43,8.81v-5.39H721.44V467h40.79V369c7.31-6.4,13-8.71,21-8.71,11.72,0,19,9.29,19,24.24V467h40.79V384.48A79.73,79.73,0,0,0,841.49,368a35.57,35.57,0,0,1,22.86-7.75c11.86,0,19.23,9.29,19.23,24.24V467h40.56V382.88c0-19.45-5.47-34.57-16.26-44.93C898.37,328.83,885.13,324,869.59,324Z"
                  transform="translate(-24 -259.34)"/>
            <path className="cls-1"
                  d="M657.81,324c-16.83,0-30.38,4.77-43.44,15.37C604.37,329.69,591,324,577.88,324a49.5,49.5,0,0,0-27.42,8.81v-5.39h-40.8v14.41a100.52,100.52,0,0,1,0,110.24v15h40.8V369c7.31-6.4,13-8.71,21-8.71,11.72,0,19,9.29,19,24.24V467h40.8V384.48A79.69,79.69,0,0,0,629.71,368a35.57,35.57,0,0,1,22.86-7.75c11.86,0,19.23,9.29,19.23,24.24V467h40.57V382.88c0-19.45-5.48-34.57-16.26-44.93C686.6,328.83,673.35,324,657.81,324Z"
                  transform="translate(-24 -259.34)"/>
            <path className="cls-1"
                  d="M247.66,323.66a75.28,75.28,0,0,1,56.1,25.06c17.48-7.42,33.82-16,49.17-24.05q5.85-3.07,11.47-6a140.82,140.82,0,0,0-16.26-18.83,145.36,145.36,0,0,0-201.22-.13,137.08,137.08,0,0,0-41,81.11c22.16-1.82,46.53-4,70.12-6.33A75.85,75.85,0,0,1,247.66,323.66Z"
                  transform="translate(-24 -259.34)"/>
            <path className="cls-1"
                  d="M306.1,446.18c-14,17.17-35.17,28.36-58.44,28.36a75.82,75.82,0,0,1-73-55.28c-23.33-2.33-47.36-4.44-69.22-6.23,7.17,70.55,68.21,125.83,142.2,125.83a143.32,143.32,0,0,0,118.81-62.57c-4.41-2.27-8.92-4.64-13.54-7.06C338.26,461.53,322.7,453.36,306.1,446.18Z"
                  transform="translate(-24 -259.34)"/>
          </g>
         <g id="Layer_3" data-name="Layer 3">
            <path className="cls-2"
                  d="M425.4,307.63c-40.73,0-101.31,57.5-182.07,69C165.84,387.74,24.33,396.93,24,397h0c.32,0,141.83,9.21,219.32,20.28,80.76,11.54,141.34,69,182.07,69,50.87,0,90.72-39.23,90.72-89.32h0C516.12,346.86,476.27,307.63,425.4,307.63ZM428.5,441a45.5,45.5,0,1,1,45.5-45.5A45.5,45.5,0,0,1,428.5,441Z"
                  transform="translate(-24 -259.34)"/>
          </g>
            </svg>
              <br/><br/>
              <h3>Community Management Console</h3>
              <br/><br/>
              <b>No internet connection detected.</b>
        </div>
      </Offline>
      </Provider>
    );
}


export default App;

