import {
    GET_COMMUNITY_DEALS,
    CLEAR_COMMUNITY_DEALS,
    COMMUNITY_DEALS_LOADING,
    SET_ACTIVE_DEAL
} from "../types";

const initialState = {
    stories: null,
    dealsLoading: true,
    limit: 10,
    page: 1,
    total: 0,
    activeDeal: null,
}

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case GET_COMMUNITY_DEALS:
            return {
                ...state,
                dealsLoading: false,
                stories: payload.docs,
                page: payload.page > payload.totalPages ? payload.totalPages : payload.page,
                total: payload.totalDocs,
                limit: payload.limit,
            }
        case CLEAR_COMMUNITY_DEALS:
            return {
                ...state,
                dealsLoading: false,
                stories: []
            }
        case COMMUNITY_DEALS_LOADING:
            return {
                ...state,
                dealsLoading: payload,
            }
        case SET_ACTIVE_DEAL:
            return {
                ...state,
                activeDeal: payload,
            }
        default:
            return state;
    }


}