import axios from 'axios';

import {GET_COMMUNITY_NEWS, CLEAR_COMMUNITY_NEWS, COMMUNITY_NEWS_LOADING} from "../types";
import { apiRoot } from "../../services/Helpers";

export const getCommunityNews = ( communityData ) => async dispatch => {
  try {
    const res = await axios.post(apiRoot()+'/news/community', communityData);
    dispatch({
      type: COMMUNITY_NEWS_LOADING,
      payload: true
    });
    dispatch({
      type: GET_COMMUNITY_NEWS,
      payload: res.data.payload
    });
  } catch(err) {
    console.log('newsload',err);
  }
};

export const deleteNewsStory = ( storyData ) => async dispatch => {
  try {
      await axios.post(apiRoot()+'/news/delete/story', storyData);
      dispatch(getCommunityNews({
        communityId: storyData.communityId,
        page: storyData.page,
        limit: storyData.limit
      }));
  } catch(err) {
    console.log('news delete error',err);
  }
};


export const clearCommunityNews = () => async dispatch => {
    dispatch({
      type: CLEAR_COMMUNITY_NEWS,
      payload: null
    })
};
