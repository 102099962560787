import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { filterEntity } from '../../services/FormService';
import { blankCommunity, blankDeal } from '../../shared/models';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Spinner from '../Layout/spinner';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityDeals } from '../../redux/actions/deals';
import DealsEditForm from './DealsEditForm';
import CommunitySwitch from '../Layout/CommunitySwitch';
import Alert from '../Layout/Alert';
import { apiRoot } from '../../services/Helpers';

const DealsEdit = (props: any) => {

    const { match, stories, activeCommunity, setAlert, removeAlert, getCommunityDeals } = props;
    const [deal, setDeal] = useState( blankDeal );
    const isNew = match.path==="/deals/new";

    const getDeal = async ( slug: string ) => {
        try {
            const res = await axios( apiRoot()+`/deals/deal/slug/${slug}` );
            if (res.data.success) {
                return res.data.payload;
            }
        } catch(err) {
            console.log('deals deal fetch error', err);
        }
    };

    let thisDeal:any = null;

    if (!isNew) {
        useEffect(() => {
            getDeal(match.params.id).then((data) => {
                setDeal(data);
            });
        }, [])
    }

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{ isNew ? 'Create' : 'Edit' } Deal</h3>
                </Col>
            </Row>
            {isNew &&
            <Row>
              <Col md={12} lg={12}>
                <Alert alertKey="dealsEdit" />
                <CommunitySwitch/>
              </Col>
            </Row>
            }
            <Row>
                <Col md={12} lg={12}>
                    { (!isNew && deal && deal.title && deal.title.length) ||
                    (isNew && deal) ?
                        <DealsEditForm deal={deal} isNew={isNew} communityId={activeCommunity} redirect={false} />
                        :
                        <Spinner height="400px" />
                    }
                </Col>
            </Row>
        </Container>
    );
};


DealsEdit.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityDeals: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
    activeCommunity: state.community.activeCommunity,
    stories: state.deals.stories
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityDeals })(DealsEdit);
