import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import { convertToDate, convertToLocal, filterEntity, rteModules } from '../../services/FormService';
import ImageUpload from '../../shared/ImageUpload';

import { Row, Col, Card, CardBody } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityNews } from '../../redux/actions/news';
import { apiRoot } from '../../services/Helpers';

const NewsEditForm = (props: any) => {

    const { news, story, isNew, communityId, getCommunityNews, setAlert, removeAlert, redirect } = props;

    const [doRedirect, setDoRedirect] = useState( redirect );
    const [imgPath, setImgPath] = useState( story.image );
    const [rteData, setRteData] = useState( story.content );
    const [storySlug, setStorySlug] = useState( story.slug );
    const [isSaving, setIsSaving] = useState(false );
    const [saveOk, setSaveOk] = useState(false );

    let localtime = convertToLocal(story.publishDate);

    let { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
            title: story.title,
            subtitle: story.subtitle,
            author: story.author,
            publishDate: story.publishDate.substr(0,10),
            publishTime: localtime.substr(16,5)
        }
    });

    const refreshImage = ( newImgPath: string ) => {
        setImgPath( newImgPath );
        // if (!isNew) {
        //     getCommunityNews({
        //         communityId: communityId,
        //         page: news.page,
        //         limit: news.limit
        //     });
        // }
    };

    const updateContent = ( html: string ) => {
        setRteData( html );
    };

    const onSubmit = async ( data: any ) => {

        setIsSaving(true);

        if (!isNew) data.id = story._id;
        data.content = rteData;
        data.communityId = communityId;
        data.image = imgPath;
        data.publishDate = convertToDate( data.publishDate, data.publishTime );

        try {
            let res: any;
            if (isNew) { res = await axios.post(apiRoot()+'/news/create', data); }
            else {       res = await axios.post(apiRoot()+'/news/update', data); }

            if (res.data.success) {
                getCommunityNews({
                    communityId: data.communityId,
                    page: news.page,
                    limit: news.limit
                });
                if (isNew) setAlert('newsEdit', 'Story created successfully', [data.title], 'success');  //key, title, errors, alertType
                if (res.data.payload.slug !== story.slug) {
                    setStorySlug( res.data.payload.slug );
                    setDoRedirect(true);
                }
            }

        } catch(err) {
            // TODO: Replace with UI Error Report
            console.log('API Error while saving profile', err);
        }

        setIsSaving(false);
        setSaveOk(true);
        setTimeout(function(){ setSaveOk(false); },5000);
    };

    return (
        doRedirect ?
            <Redirect to={`/refresher/news/edit/${storySlug}`} />
            :
        <Card>
            <CardBody>
                <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={6}>
                            <div className="form-group">
                                <label className="req">{ errors.title && <span>Required</span> }Title</label>
                                <input type="text" name="title" className={`form-control`}
                                       ref={register({ required: true })}/>
                            </div>
                            <div className="form-group">
                                <label>{ errors.subtitle && <span>Required</span> }Subtitle</label>
                                <input type="text" name="subtitle" className={`form-control`}
                                       ref={register}/>
                            </div>
                            <div className="form-group">
                                <label className="req">{ errors.author && <span>Required</span> }Author Name</label>
                                <input type="text" name="author" className={`form-control`}
                                       ref={register({ required: true })}/>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="req">{errors.publishDate && <span>Required</span>}Publish Date</label>
                                        <input type="date" name="publishDate" className={`form-control`}
                                               ref={register({ required: true })}/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="req">{errors.publishTime && <span>Required</span>}Publish Time</label>
                                        <input type="time" name="publishTime" className={`form-control`}
                                               ref={register({ required: true })}/>
                                    </div>
                                </Col>
                            </Row>

                            <div className="form-group">
                                <ImageUpload label="Story Photo" onUpload={refreshImage} imageTarget="news/image" currentImage={imgPath} targetId={isNew ? 'new' : story._id} />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="form-group">
                                <label>Story Body</label>
                                <ReactQuill value={rteData || ''} onChange={(html: string) => updateContent(html)}
                                            modules={rteModules}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br/>
                            <div className={`form-group saving-button text-center`}>
                                <i className="fad fa-hurricane fa-fw"></i>
                                <button type={`submit`} className={`btn btn-primary ` + (isSaving ? `disabled` : '')} disabled={isSaving}>{isNew ? 'Save and Continue' : 'Save Changes'}</button>
                                <i className={`fa-fw ` + (isSaving ? `fad fa-hurricane fa-spin active` : saveOk ? 'far fa-check-circle active' : 'fad fa-hurricane')}></i>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    );
};



NewsEditForm.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityNews: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    news: state.news
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityNews })(NewsEditForm);


