import axios from 'axios';

import {GET_COMMUNITY_EVENTS, CLEAR_COMMUNITY_EVENTS, COMMUNITY_EVENTS_LOADING} from "../types";
import { apiRoot } from "../../services/Helpers";

export const getCommunityEvents = ( communityData ) => async dispatch => {
  try {
    const res = await axios.post(apiRoot()+'/events/community', communityData);
    dispatch({
      type: COMMUNITY_EVENTS_LOADING,
      payload: true
    });
    dispatch({
      type: GET_COMMUNITY_EVENTS,
      payload: res.data.payload
    });
  } catch(err) {
    console.log('eventload',err);
  }
};

export const deleteEvent = ( eventData ) => async dispatch => {
  try {
      await axios.post(apiRoot()+'/events/delete/event', eventData);
      dispatch(getCommunityEvents({
        communityId: eventData.communityId,
        page: eventData.page,
        limit: eventData.limit
      }));
  } catch(err) {
    console.log('event delete error',err);
  }
};


export const clearCommunityEvents = () => async dispatch => {
    dispatch({
      type: CLEAR_COMMUNITY_EVENTS,
      payload: null
    })
};

