import range from 'lodash.range';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface CustPagProps {
  onChangePage: (value: any) => void,
  onChangeRowCt: (value: any) => void,
  itemsCount: number,
  itemsToShow: number,
  pageOfItems: number,
};

export default class CustPag extends Component<CustPagProps> {

  constructor(props: CustPagProps, context: any) {
    super(props, context);
    const itemsPerPage = props.itemsToShow;
  }

  setPage = (page: any) => {
    const { itemsCount, itemsToShow, onChangePage } = this.props;

    // calculate total pages
    const totalPages = Math.ceil(itemsCount / itemsToShow);

    if (page < 1 || page > totalPages) {
      return;
    }

    // call change page function in parent component
    onChangePage(page);
  };

  setRowCt = ( rowCt: any ) => {
    const { onChangeRowCt } = this.props;
    const itemsPerPage = parseInt(rowCt);
    onChangeRowCt(itemsPerPage);
  }

  getPager = (totalItems: number, curPage: number, pSize: number) => {
    // default to first page
    const currentPage = curPage || 1;

    // default page size is 10
    const pageSize = pSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    }
    else if (currentPage <= 2) {
      // more than 5 total pages so calculate start and end pages
      startPage = 1;
      endPage = totalPages >= 5 ? 5 : totalPages;
    }
    else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    }
    else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    // calculate start and end item indexes
    const startIndex = startPage - 1;
    const endIndex = endPage - 1;
    const prevPage = curPage - 1 < 1 ? 1 : curPage - 1;
    const nextPage = curPage + 1 > totalPages ? totalPages : curPage + 1;

    // create an array of pages to ng-repeat in the pager control
    const pages = range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      prevPage,
      nextPage
    };
  };

  render() {
    const { itemsCount, itemsToShow, pageOfItems } = this.props;
    const paginfo = this.getPager(itemsCount, pageOfItems, itemsToShow);
    const pages = paginfo.pages;


    // console.log('ItemCt:', itemsToShow);

    return itemsCount ? (
        <div className="pagination__wrap">
          <div className="pagination-info bold">
          <span>Displaying records {`${(itemsToShow * (pageOfItems - 1)) + 1} `}
            to {itemsToShow * pageOfItems > itemsCount ? itemsCount
                : itemsToShow * pageOfItems} of {itemsCount}
          </span>
          </div>
          {(itemsCount <= 1) ? ''
              : (
                  <Pagination className="pagination">
                    <select id="rowsPerPage" onChange={(e) => this.setRowCt(e.target.value)} value={itemsToShow}>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <PaginationItem className="pagination__item" disabled={pageOfItems === 1}>
                      <PaginationLink
                          className={`pagination__link pagination__link--arrow` + (pageOfItems === 1 ? ` pag-opacity` : ``)}
                          type="button"
                          onClick={() => this.setPage(paginfo.prevPage)}
                      >
                        <i className="fas fa-chevron-left fa-fw"></i>
                      </PaginationLink>
                    </PaginationItem>
                    {pages.map(page => (
                        <PaginationItem
                            className="pagination__item"
                            key={page}
                            active={pageOfItems === page}
                        >
                          <PaginationLink
                              className="pagination__link"
                              type="button"
                              onClick={() => this.setPage(page)}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                    ))
                    }
                    <PaginationItem className="pagination__item" disabled={pageOfItems === paginfo.endPage}>
                      <PaginationLink
                          className={`pagination__link pagination__link--arrow` + (pageOfItems === paginfo.endPage ? ` pag-opacity` : ``)}
                          type="button"
                          onClick={() => this.setPage(paginfo.nextPage)}
                      >
                        <i className="fas fa-chevron-right fa-fw"></i>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
              )
          }
        </div>
    ) : <div />;
  }
}

