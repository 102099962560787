import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import Scrollbar from 'react-smooth-scrollbar';

import SidebarContent from './SidebarContent';

export interface SidebarField {
    show: boolean,
    collapse: boolean
}

const Sidebar: FunctionComponent = ({}) => {

  return (
    <div className={'sidebar'}>
      <button type="button" className="sidebar__back" />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent />
        </div>
      </Scrollbar>
    </div>
  );
};

export default Sidebar;
 