import React, { Component } from 'react';

import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';

interface SidebarContentProps {
};

class SidebarContent extends Component<SidebarContentProps> {

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" route="/dashboard" icon="chart-line" />
          <SidebarLink title="Communities" route="/communities" icon="users" />
          <SidebarLink title="News" route="/news" icon="newspaper" />
          <SidebarLink title="Events" route="/events" icon="calendar-alt" />
          <SidebarLink title="Deals" route="/deals" icon="tags" />
          <SidebarLink title="Billing" route="/billing" icon="usd-circle" />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
