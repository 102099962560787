import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../redux/actions/alert';
import { registerUser } from '../redux/actions/auth';
import logo from '../shared/img/logo/commet-logo.svg';
import { Card, CardBody, Col, Container, Row, Button, CardHeader } from "reactstrap";
import PropTypes from 'prop-types';
import Alert from '../containers/Layout/Alert';
import axios from 'axios';
import { Redirect } from 'react-router';
import { useForm } from 'react-hook-form';
import { formError, mapClass } from '../services/FormService';
import { apiRoot } from '../services/Helpers';

const UserRegister = (props: any) => {
    const [showPassword, toggleShowPassword] = useState(false);
    const [usernameOK, setUsernameOK] = useState( -1 );
    const [emailOK, setEmailOK] = useState( -1 );
    const [phoneOK, setPhoneOK] = useState( -1 );

    const { setAlert, registerUser, isAuthenticated } = props;

    const onBlur = (e:any) => {
        if (e.target.value.length >= 4) {
            const field = e.target.name == 'UserName' ? "username" : "email";
            //setFormData({ ...formData, [e.target.name]: e.target.value });
            if (field == 'username') { setUsernameOK(0); } else { setEmailOK(0); }
            checkUserValue( field, e.target.value );
        }
        return true;
    };

    const checkUserValue = async (field: string, value: string) => {
        console.log('checking '+field);
        try {
            if (field && value.length >= 4) {
                const res = await axios.get(apiRoot()+'/auth/check/' + field + '/' + value);
                if (res.data.result === false) {
                    if (field == 'username') { setUsernameOK(1); } else { setEmailOK(1); }
                } else {
                    if (field == 'username') { setUsernameOK(2); } else { setEmailOK(2); }
                }
            }
        } catch (err) {
            console.log('Server error on field value check', err);
            if (field == 'username') { setUsernameOK(2); } else { setEmailOK(2); }
        }
    };




    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            UserName: '',
            UserEmail: '',
            UserPassword: '',
            UserPhone: ''
        }
    });
    const onSubmit = ( values: any ) => {
        //e.preventDefault();
        registerUser({ username: values.UserName, email: values.UserEmail, password: values.UserPassword, phone: values.UserPhone });
    };


    // Redirect if already signed in
    if (isAuthenticated) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className="cmt-login">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Commet Logo" className="logo"/>
                                <h3>Community Management Console</h3>
                            </div>
                            <Card>
                                <CardHeader>Create an Account</CardHeader>
                                <CardBody>
                                    <Alert key={`registerError`}/>
                                    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className={`form-group`}>
                                            <label>
                                                {errors.UserName ? <span>Username Is Invalid</span> : usernameOK==2 && <span>Username Already In Use</span>}
                                                Username
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-user-astronaut"></i></div>
                                                <input
                                                    name="UserName"
                                                    type="text"
                                                    onChange={e => onBlur(e)}
                                                    onBlur={e => onBlur(e)}
                                                    className={`form-control input-ico-left ` + mapClass(usernameOK, !!errors.UserName)}
                                                    ref={register({ required: true, minLength: 4, maxLength: 24 })}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>
                                                {errors.UserEmail ? <span>Email Is Invalid</span> : emailOK==2 && <span>Email Already In Use</span>}
                                                Email Address
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="far fa-envelope"></i>
                                                </div>
                                                <input
                                                    name="UserEmail"
                                                    type="text"
                                                    onChange={e => onBlur(e)}
                                                    onBlur={e => onBlur(e)}
                                                    className={`form-control input-ico-left ` + mapClass(emailOK, !!errors.UserEmail)}
                                                    ref={register({
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>
                                                {errors.UserPhone && <span>Phone is Required</span>}
                                                Phone
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="far fa-mobile"></i>
                                                </div>
                                                <input
                                                    name="UserPhone"
                                                    type="text"
                                                    onChange={e => onBlur(e)}
                                                    onBlur={e => onBlur(e)}
                                                    className={`form-control input-ico-left ` + mapClass(phoneOK, !!errors.UserPhone)}
                                                    ref={register({
                                                        required: 'Required'
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>
                                                {errors.UserPassword && <span>Password Must Be At Least 8 Characters</span>}
                                                Password
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-key"></i></div>
                                                <input
                                                    name="UserPassword"
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control input-ico-left input-ico-right"
                                                    ref={register({ required: true, minLength: 8, maxLength: 64 })}
                                                />
                                                <div className="ico-right"
                                                     onClick={() => toggleShowPassword(!showPassword)}>
                                                    <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <button type={`submit`} className={`btn btn-primary account__btn`}>Register</button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

UserRegister.propTypes = {
    setAlert: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, registerUser })(UserRegister);
