import axios from 'axios';
import {
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    CLEAR_PROFILE,
    CLEAR_USER_COMMUNITIES,
    CLEAR_COMMUNITY_NEWS,
    CLEAR_COMMUNITY_DEALS
} from '../types';

import { setAlert, removeAlert } from "./alert";
import setAuthToken from "../../utils/setAuthToken";
import { apiRoot } from "../../services/Helpers";

// LOAD USER
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(apiRoot()+'/auth');
        if (res.data.payload && res.data.payload.isActive === false) {
            dispatch( setAlert( 'signinError', 'There was a problem signing in:', ['This account has been disabled by Commet admins.'], 'danger') );

        }
        else if (res.data.payload && res.data.payload.isBanned === true) {
            dispatch( setAlert( 'signinError', 'There was a problem signing in:', ['This account has been banned by Commet admins.'], 'danger') );
        }
        else {
            dispatch({ type: USER_LOADED, payload: res.data.payload });
        }

    } catch(err) {
        dispatch({
           type: AUTH_ERROR
        });
    }
};



// Login User
export const login = ({ userOrEmail, password }) => async (dispatch) => {
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };
    const body = JSON.stringify({ userOrEmail, password });
    apiRoot();
    try {
        const res = await axios.post(apiRoot()+'/auth/login', body, config);
        if (res.data)
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch(removeAlert('signinError'));
        dispatch(loadUser());

    } catch(err) {
        const errors = err.response.data.errors;
        if (errors) {
            dispatch( setAlert( 'signinError', 'There was a problem signing in:', errors, 'danger') );
        }
        dispatch({ type: LOGIN_FAIL });
    }
};



// Register User
export const registerUser = ({ username, email, password, phone }) => async (dispatch) => {
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };
    const body = JSON.stringify({ username, email, password, phone } );

    try {
        const res = await axios.post(apiRoot()+'/auth/register', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });
        dispatch(removeAlert('registerError'));
        dispatch(loadUser());
        
    } catch(err) {
        const errors = err.response.data.errors;
        if (errors) {
            dispatch( setAlert( 'regError', 'There was a problem creating your account:', errors, 'danger') );
        }
        dispatch({ type: REGISTER_FAIL });
    }
};


// Logout User / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: CLEAR_USER_COMMUNITIES });
    dispatch({ type: CLEAR_COMMUNITY_NEWS });
    dispatch({ type: CLEAR_COMMUNITY_DEALS });
    dispatch({ type: LOGOUT });
}
