export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const GET_USER_COMMUNITIES = 'GET_USER_COMMUNITIES';
export const CLEAR_USER_COMMUNITIES = 'CLEAR_USER_COMMUNITIES';
export const USER_COMMUNITIES_ERROR = 'USER_COMMUNITIES_ERROR';
export const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY';

export const GET_COMMUNITY_NEWS = 'GET_COMMUNITY_NEWS';
export const CLEAR_COMMUNITY_NEWS = 'CLEAR_COMMUNITY_NEWS';
export const COMMUNITY_NEWS_LOADING = 'COMMUNITY_NEWS_LOADING';
export const SET_ACTIVE_STORY = 'SET_ACTIVE_STORY';

export const GET_COMMUNITY_DEALS = 'GET_COMMUNITY_DEALS';
export const CLEAR_COMMUNITY_DEALS = 'CLEAR_COMMUNITY_DEALS';
export const COMMUNITY_DEALS_LOADING = 'COMMUNITY_DEALS_LOADING';
export const SET_ACTIVE_DEAL = 'SET_ACTIVE_DEAL';

export const GET_COMMUNITY_EVENTS = 'GET_COMMUNITY_EVENTS';
export const CLEAR_COMMUNITY_EVENTS = 'CLEAR_COMMUNITY_EVENTS';
export const COMMUNITY_EVENTS_LOADING = 'COMMUNITY_EVENTS_LOADING';
export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';

export const ADD_BEACON = 'ADD_BEACON';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
