import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Refresher = (props: any) => {

    const { match } = props;

    return (
        <Redirect to={`/${match.params.type}/${match.params.action}/${match.params.slug}`} />
    );
};

export default Refresher;