import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import CommunitySwitch from '../Layout/CommunitySwitch';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCommunityDeals, deleteDeal } from '../../redux/actions/deals';
import Spinner from '../Layout/spinner';
import Alert from '../Layout/Alert';
import { Link } from 'react-router-dom';
import { communityLocked, lastItem } from '../../services/FormService';
import CustPag from '../../shared/components/pagination/Pagination';


const DealsList = (props: any) => {

    const { deals, community, setAlert, removeAlert, getCommunityDeals, deleteDeal } = props;
    const activeCommunity = community.activeCommunity;
    const isLocked = communityLocked( activeCommunity, community.communities);

    if (isLocked) {
        //key, title, errors, alertType
        setAlert('communityLockedError','Community is locked',['You cannot perform any modifications to this community until billing is resolved.'], 'warning');
    } else {
        removeAlert('communityLockedError');
    }

    const moment = require('moment');

    useEffect(() => {
        getCommunityDeals({
            communityId: activeCommunity,
            page: deals.page,
            limit: deals.limit
        });
    }, [activeCommunity]);

    const changePage = ( page: number ) => {
        getCommunityDeals({
            communityId: activeCommunity,
            page: page,
            limit: deals.limit
        })
    };

    const changeLimit = ( newLimit: number ) => {
        getCommunityDeals({
            communityId: activeCommunity,
            page: deals.page,
            limit: newLimit
        })
    };

    const deleteStory = ( storyId: string ) => {
        deleteDeal({
            communityId: activeCommunity,
            page: deals.page,
            limit: deals.limit,
            storyId: storyId
        });
    };

    return (
        <Container className="dashboard">
            <Row>
                <Col md={8}>
                    <h3 className="page-title">Deals</h3>
                </Col>
                <Col md={4} className={`text-right`}>
                    {!isLocked &&
                    <Link to={`/deals/new`} className="btn btn-sm btn-pill btn-success"><i
                      className="fad fa-plus-circle fa-lg"></i> &nbsp;New Deal
                    </Link>
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <CommunitySwitch/>
                </Col>
            </Row>
            <Alert alertKey='dealsLoadingError' />
            <Alert alertKey='communityLockedError' />
            <Row>
                <Col md={12} lg={12}>
                    {deals.dealsLoading ? <Spinner height={'200px'}/> :
                        <Card>
                            <CardHeader><i className="fad fa-tags red"></i> &nbsp;Deals You've
                                Created</CardHeader>
                            <CardBody>
                                <CustPag onChangePage={changePage} onChangeRowCt={changeLimit} itemsCount={deals.total} itemsToShow={deals.limit} pageOfItems={deals.page} />
                                {deals && deals.stories && deals.stories.length > 0 ?
                                    <table className="table table-hover no-top-border">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Title</th>
                                            {/*<th>Impressions</th>*/}
                                            <th className={`center-cell`}>Views</th>
                                            <th className={`center-cell`}>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>{
                                            deals.stories.map((n: any) =>
                                                <tr key={n._id}>
                                                    <td>{moment(n.publishDate).format('MM/DD/YYYY')}</td>
                                                    <td>{n.title}</td>
                                                    {/*<td>{n.Counts.Impressions}</td>*/}
                                                    <td className={`center-cell`}>{n.engagement.views}</td>
                                                    <td className={`center-cell`}>
                                                        { isLocked ? <i className="fad fa-lock fa-fw"></i> :
                                                            <>
                                                                <Link className="btn btn-sm btn-primary" to={`/deals/edit/` + n.slug}>Edit</Link>
                                                                <button className="btn btn-sm btn-primary" onClick={()=>deleteStory(n._id)}>Delete</button>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    :
                                    <table className="table table-hover no-top-border">
                                        <thead>
                                        <tr>
                                            <th>There are no deals for this community.</th>
                                        </tr>
                                        </thead>
                                    </table>
                                }
                            </CardBody>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
};


DealsList.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityDeals: PropTypes.func.isRequired,
    deleteDeal: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
    deals: state.deals,
    community: state.community
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityDeals, deleteDeal })(DealsList);

