import axios from 'axios';
//import { setAlert} from "./alert";

import { GET_PROFILE, PROFILE_ERROR } from "../types";
import { apiRoot } from "../../services/Helpers";

// Get the current user's profile
export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await axios.get(apiRoot()+'/profile/me');
    dispatch({
      type: GET_PROFILE,
      payload: res.data.payload
    })
  } catch(err) {
    const msg = 'An Error Occurred'; //err.response.statusText ? err.response.statusText : 'An Error Occurred';
    const status = 'Please check console for more details'; //err.response.status ? err.response.status : 'Please check console for more details';

    console.error('getProfileError', err);

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: msg, status: status }
    })
  }
}

