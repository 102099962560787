import React, { useState } from 'react';
import { filterEntity } from '../services/FormService';

const PlaceSelector = ( props: any ) => {

    const { communities, communityId, selectedPlaces, onPlaceClick } = props;

    let placesIds = selectedPlaces.map( (sp:any) => {
        return sp._id;
    });

    let community = {
        places: []
    };

    if (communities.length && communityId) {
        community = filterEntity( communities, '_id', communityId);
    }

    return (
      <div>
          { community.places.map((p:any) =>
              <div className="place-checkbox" key={p._id}>
                  <input type="checkbox"
                         id={p._id}
                         value={p._id}
                         checked={selectedPlaces.includes(p._id)}
                         onChange={() => onPlaceClick( p._id, event )}
                  />
                  <label className="place-checkbox-content" htmlFor={p._id}>
                      <b>{p.name}</b>
                      <br/><i>{p.formatted_address}</i>
                  </label>
              </div>
          )}
      </div>
    );
};

export default PlaceSelector;