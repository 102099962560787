import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setActiveCommunity } from '../../redux/actions/community';
import Spinner from './spinner';

const CommunitySwitch = (props: any) => {

    const { communities, activeCommunity, setActiveCommunity } = props;

    console.log('activeCommunity', activeCommunity);

    return (
        communities.length > 0 ?
            <div className="input-group community-switch">
                <div className="input-group-prepend">
                    <div className="input-group-text">Active Community</div>
                </div>
                <select className={`form-control`}
                        onChange={(event) => setActiveCommunity(event.target.value)}
                        defaultValue={activeCommunity}>
                    {communities.map((c: any) =>
                        <option key={c._id} value={c._id}>{c.name}</option>
                    )}
                </select>
            </div>
            :
            <></>
    );
}


CommunitySwitch.propTypes = {
    setActiveCommunity: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    communities: state.community.communities,
    activeCommunity: state.community.activeCommunity,
});

export default connect(mapStateToProps, { setActiveCommunity })(CommunitySwitch);

