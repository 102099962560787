import React, { Fragment } from 'react';

const Spinner = (props: any) => {

    let loaderClass = 'loader';
    const styles = {
        height: '100vh',
        fontSize: '50px',
        lineHeight: '50px'
    };
    if (props.height) {
        styles.height = props.height
        styles.lineHeight = props.height;
    } else {
        loaderClass = 'loader-full';
    }
    if (props.size) {
        styles.fontSize = props.size
        styles.lineHeight = props.size
    }

    return (
        <div className={loaderClass} style={ styles }>
            <i className="fad fa-hurricane fa-spin"></i>
        </div>
    );
};

export default Spinner;