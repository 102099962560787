import DownIcon from 'mdi-react/ChevronDownIcon';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { logout } from '../../../redux/actions/auth';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Topbar = (props: any) => {

    const { logout, userData } = props;
    const [menuOpen, toggleMenu] = useState(false);

    const avatar = userData && userData.avatar ? userData.avatar : 'user/avatars/default';
    const firstName = userData && userData.firstName ? userData.firstName : '';
    const lastName = userData && userData.lastName ? userData.lastName : '';

    return (
        <div className="topbar">
            <div className="topbar__wrapper">
                <div className="topbar__left">
                    {/*<TopbarSidebarButton/>*/}
                    &nbsp;&nbsp;&nbsp;&nbsp;<Link className="topbar__logo" to="/dashboard_default"/>
                </div>
                <div className="topbar__right">
                    <div className="topbar__profile">
                        <button type="button" className="topbar__avatar" onClick={() => toggleMenu(!menuOpen)}>
                            <img className="topbar__avatar-img"
                                 src={`https://commet.s3.amazonaws.com/`+avatar}/>
                            <p className="topbar__avatar-name">{firstName} {lastName}</p>
                            <DownIcon className="topbar__icon"/>
                        </button>
                        {menuOpen &&
                        <button type="button" className="topbar__back" onClick={() => toggleMenu(!menuOpen)}/>}
                        <Collapse isOpen={menuOpen} className="topbar__menu-wrap">
                            <div className="topbar__menu">
                                <TopbarMenuLink title="My Profile" icon="list" path="/my-profile" />

                                <div className="topbar__menu-divider"/>

                                <Link className="topbar__link" onClick={logout} to={`#`}>
                                    <span className={`topbar__link-icon lnr lnr-exit`}/>
                                    <p className="topbar__link-title">Sign Out</p>
                                </Link>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );

}

Topbar.propTypes = {
    logout: PropTypes.func.isRequired,
}


export default connect(null, { logout })(Topbar);