import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import CommunitySwitch from '../Layout/CommunitySwitch';
import { communityLocked, lastItem } from '../../services/FormService';
import Spinner from '../Layout/spinner';
import Alert from '../Layout/Alert';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CustPag from '../../shared/components/pagination/Pagination';
import { getCommunityEvents, deleteEvent } from '../../redux/actions/events';


const EventsList = (props: any) => {

    const { events, community, setAlert, removeAlert, getCommunityEvents, deleteEvent} = props;
    const activeCommunity = community.activeCommunity;
    const isLocked = communityLocked( activeCommunity, community.communities);

    if (isLocked) {
        //key, title, errors, alertType
        setAlert('communityLockedError','Community is locked',['You cannot perform any modifications to this community until billing is resolved.'], 'warning');
    } else {
        removeAlert('communityLockedError');
    }

    const moment = require('moment');

    useEffect(() => {
        getCommunityEvents({
            communityId: activeCommunity,
            page: events.page,
            limit: events.limit
        });
    }, [activeCommunity]);

    const changePage = ( page: number ) => {
        getCommunityEvents({
            communityId: activeCommunity,
            page: page,
            limit: events.limit
        })
    };

    const changeLimit = ( newLimit: number ) => {
        getCommunityEvents({
            communityId: activeCommunity,
            page: events.page,
            limit: newLimit
        })
    };

    const deleteThisEvent = ( eventId: string ) => {
        deleteEvent({
            communityId: activeCommunity,
            page: events.page,
            limit: events.limit,
            eventId: eventId
        });
    };

        return (
            <Container className="dashboard">
                <Row>
                    <Col md={8}>
                        <h3 className="page-title">Events</h3>
                    </Col>
                    <Col md={4} className={`text-right`}>
                    {!isLocked &&
                    <Link to={`/events/new`} className="btn btn-sm btn-pill btn-success"><i
                      className="fad fa-plus-circle fa-lg"></i> &nbsp;Create Event
                    </Link>
                    }                    
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                    <CommunitySwitch/>
                    </Col>
                </Row>
                <Alert alertKey='eventsLoadingError' />
                <Alert alertKey='communityLockedError' />
                <Row>
                    <Col md={12} lg={12}>
                    { events.eventsLoading ? <Spinner height={'200px'} />
                    :
                    <Card>
                    <CardHeader><i className="fad fa-newspaper red"></i> &nbsp;Events You've Created</CardHeader>
                    <CardBody>
                        <CustPag onChangePage={changePage} onChangeRowCt={changeLimit} itemsCount={events.total} itemsToShow={events.limit} pageOfItems={events.page} />
                        { events && events.events && events.events.length > 0 ?
                            <table className="table table-hover no-top-border">
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Dates</th>
                                    <th>Location</th>
                                    <th>Views</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>{
                                    events.events.map((e:any) => {
                                        return (
                                            <tr key={e._id}>
                                                <td>{e.title}</td>
                                                <td>{e.dates.length}</td>
                                                <td>{ e.location.type === 'other' ? e.location.text :
                                                      e.location.type === 'url' ? e.location.url :
                                                      e.location.type === 'place' ? e.location.places[0].name :
                                                      e.location.type === 'otherPlace' ? e.location.places[0].name :
                                                      ''
                                                }</td>
                                                <td>{e.engagement.views}</td>
                                                <td><Link className="btn btn-sm btn-primary" to={`/events/edit/` + e.slug}>Edit</Link></td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                            :
                            <table className="table table-hover no-top-border">
                                <tr><th>There are no events for this community.</th></tr>
                            </table>
                        }

                    </CardBody>
                </Card>                    
                }


                    </Col>
                </Row>
            </Container>
        );
    }


EventsList.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityEvents: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
    events: state.events,
    community: state.community
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityEvents, deleteEvent })(EventsList);

