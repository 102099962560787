import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../containers/Layout';
import CommunitiesList from '../containers/Communities/CommunitiesList';
import CommunityEdit from '../containers/Communities/CommunityEdit';
import Dashboard from '../containers/Dashboard/Dashboard';
import NewsList from '../containers/News/NewsList';
import NewsEdit from '../containers/News/NewsEdit';
import EventsList from '../containers/Events/EventsList';
import EventEdit from '../containers/Events/EventEdit';
import DealsList from '../containers/Deals/DealsList';
import DealsEdit from '../containers/Deals/DealsEdit';
import Billing from '../containers/Billing/Billing';
import Refresher from './Refresher';

import { connect } from 'react-redux';
import { getCurrentProfile } from '../redux/actions/profile';
import { getCommunities } from '../redux/actions/community';
import Spinner from '../containers/Layout/spinner';
import MyAccount from '../containers/MyProfile/MyProfile';

import { checkProfileComplete } from '../services/FormService';
import { removeAlert } from '../redux/actions/alert';
import CmtModal from '../shared/CmtModal';

const RouterWrapped = (props: any) => {

    const {
        getCurrentProfile,
        getCommunities,
        removeAlert,
        modal,
        location: {
            pathname
        },
        profile: {
            profile,
            loading
        },
    } = props;

    useEffect(() => {
        getCurrentProfile();
        getCommunities();
    }, []);


    let isProfileComplete = null;
    if (!loading && profile) {
        isProfileComplete = checkProfileComplete(profile);
    }

    // REDIRECT USERS TO MY-PROFILE IF THEIR PROFILE IS NOT COMPLETE

    return loading && profile === null ? <Spinner/> :
        isProfileComplete === false && pathname !== '/my-profile' ? <Redirect to={`/my-profile`}/> :
            (
                <div>
                    <Layout userData={profile}/>
                    <div className="container__wrap">
                        <Route exact path="/dashboard" component={Dashboard}/>

                        <Route exact path="/communities/edit/:id" component={CommunityEdit}/>
                        <Route exact path="/communities/new" component={CommunityEdit}/>
                        <Route exact path="/communities" component={CommunitiesList}/>

                        <Route exact path="/news/edit/:id" component={NewsEdit}/>
                        <Route exact path="/news/new" component={NewsEdit}/>
                        <Route exact path="/news" component={NewsList}/>

                        <Route exact path="/deals/edit/:id" component={DealsEdit}/>
                        <Route exact path="/deals/new" component={DealsEdit}/>
                        <Route exact path="/deals" component={DealsList}/>

                        <Route exact path="/events/edit/:id" component={EventEdit}/>
                        <Route exact path="/events/new" component={EventEdit}/>
                        <Route exact path="/events" component={EventsList}/>

                        <Route exact path="/billing" component={Billing}/>

                        <Route exact path="/my-profile" component={MyAccount} userProfile={profile} isProfileComplete={isProfileComplete} />

                        {/* This is crazy - don't ask
                            Actually, the reason is that when people change the title of a piece of content
                            and causes a slug to change, we need to redirect the user to the URL with the new slug.
                            But when I did that, I kept getting stuck an infinite loop because the state
                            wasn't resetting and "doRedirect" was stuck on true. So I decided to pass it through
                            another route hoping that would reset the state, and it did. Yay.
                        */}
                        <Route exact path="/refresher/:type/:action/:slug" component={Refresher} />
                    </div>
                    <CmtModal />
                </div>
            );

};


RouterWrapped.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    getCommunities: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    profile: state.profile,
    isAuthenticated: state.auth.isAuthenticated,
    modal: state.modal
});

export default connect(mapStateToProps, { getCurrentProfile, getCommunities, removeAlert })(RouterWrapped);
