import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

const Dashboard = (props: any) => {
    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Dashboard</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;