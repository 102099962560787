import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import RouterWrapped from '../../routing/RouterWrapped';

import { connect } from 'react-redux';
import { getCommunities } from '../../redux/actions/community';
import { apiRoot } from '../../services/Helpers';

export const CommunityAdminsForm = (props: any) => {

    const { communityData, formState, getCommunities } = props;
    let c = communityData;

    const [searchData, setSearchData] = useState({ users: [], loading: false, value: ''});

    const search = async (val:string) => {
        if (val.length > 3) {
            setSearchData( { ...searchData, loading: true } );
            const res = await axios( apiRoot()+`/profile/search/${val}` );
            const users = res.data.payload;
            setSearchData( { ...searchData, users: users, loading: false } );
        }
    };

    const onChangeHandler = async (e:any) => {
        search(e.target.value);
        setSearchData( { ...searchData, value: e.target.value } );
    };

    const addAdminToCommunity = async ( userId: string, communityId: string, e:any ) => {
        e.preventDefault();
        const response = confirm("Are you sure you want to make this user an administrator?");
        if (response) {
            const data = { userId: userId, communityId: communityId };
            const res = await axios.post( apiRoot()+`/community/admin/add`, data );
            console.log(res);
            if (res.data.success === true) {
                console.log('refreshing communities');
                setSearchData({ users: [], loading: false, value: ''});
                getCommunities();
            }
        }
    };

    const removeAdminFromCommunity = async ( userId: string, communityId: string, e:any ) => {
        e.preventDefault();
        const response = confirm("Are you sure you remove this user from the list of community administrators?");
        if (response) {
            const data = { userId: userId, communityId: communityId };
            const res = await axios.post( apiRoot()+`/community/admin/remove`, data );
            console.log(res);
            if (res.data.success === true) {
                console.log('refreshing communities');
                getCommunities();
            }
        }
    };

    return (
        <Card>
            <CardHeader><i className="fad fa-user-cog fa-fw red"></i> &nbsp;Community Administrators</CardHeader>
            <CardBody>
                <div className={`card-info`}>Add Commet users who will have the ability to manage this community.</div>
                <div className={`admin-search`}>
                    <label>Search Users: <span>(User must be a member of this community)</span></label>
                    <input
                        className={`form-control`}
                        onChange={(e:any) => onChangeHandler(e)}
                        placeholder="Search users by name, username or email"
                    />
                </div>

                <div className={`community-admins small`}>
                    {searchData.users.length > 0 && searchData.users.map((user: any) =>
                        <div className={`community-admin`} key={user.user} onClick={() => { addAdminToCommunity(user.user, c._id, event) }}>
                            <img src={`https://commet.s3.amazonaws.com/` + user.avatar}/>
                            <b>{user.firstName} {user.lastName}</b>
                            <br/>{user.username}
                        </div>
                    )}
                </div>

                <h4>Current Administrators:</h4>
                <div className={`community-admins`}>
                    {c.admins.map((admin: any) =>
                        <div className={`community-admin`} key={admin._id}>
                            {   admin.profile.avatar ?
                                <img src={`https://commet.s3.amazonaws.com/` + admin.profile.avatar}/>
                                :
                                <img src={`https://commet.s3.amazonaws.com/user/avatars/default`}/>
                            }
                            <b>{admin.profile.firstName} {admin.profile.lastName}</b>
                            <br/>{admin.username}
                            <div>
                                <a href={`#`} onClick={() => { removeAdminFromCommunity(admin._id, c._id, event) }}>
                                    <i className={`far fa-times-circle`}></i> Remove
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card>
    );
};


CommunityAdminsForm.propTypes = {
    getCommunities: PropTypes.func.isRequired,
};


export default connect(null, { getCommunities })(CommunityAdminsForm);

