import { OPEN_MODAL, CLOSE_MODAL } from "../types";

export const openModal = ( modalType, modalData = {} ) => (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload: {
      modalType : modalType,
      modalData : modalData
    }
  });
}

export const closeModal = () => (dispatch) => {
  dispatch({ type: CLOSE_MODAL  });
}
