import {
    GET_COMMUNITY_NEWS,
    CLEAR_COMMUNITY_NEWS,
    COMMUNITY_NEWS_LOADING,
    SET_ACTIVE_STORY
} from "../types";

const initialState = {
    stories: null,
    newsLoading: true,
    limit: 10,
    page: 1,
    total: 0,
    activeStory: null,
}

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case GET_COMMUNITY_NEWS:
            return {
                ...state,
                newsLoading: false,
                stories: payload.docs,
                page: payload.page > payload.totalPages ? payload.totalPages : payload.page,
                total: payload.totalDocs,
                limit: payload.limit,
            }
        case CLEAR_COMMUNITY_NEWS:
            return {
                ...state,
                newsLoading: false,
                stories: []
            }
        case COMMUNITY_NEWS_LOADING:
            return {
                ...state,
                newsLoading: payload,
            }
        case SET_ACTIVE_STORY:
            return {
                ...state,
               activeStory: payload,
            }
        default:
            return state;
    }


}