import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import CommunityDetailForm from './CommunityDetailForm';
import Spinner from '../Layout/spinner';
import { filterEntity } from '../../services/FormService';
import { blankCommunity} from '../../shared/models';
import CommunityPlacesForm from './CommunityPlacesForm';
import CommunityAdminsForm from './CommunityAdminsForm';
import Alert from '../Layout/Alert';
import { removeAlert, setAlert } from '../../redux/actions/alert';

const CommunityEdit = (props: any) => {

    const { communities, match, setAlert, removeAlert } = props;
    const [formState] = useState( match.path === '/communities/new' ? 'create' : 'edit' );

    let c:any = {};
    if (formState == 'edit') {
        c = communities ? filterEntity( communities, 'slug', match.params.id ) : null;
    } else {
        c = blankCommunity;
    }

    console.log('commData', c);

    if (formState == 'edit' && c && !c.active) {
        setAlert(c ? c.slug : 'communityEdit', 'This community is disabled.',
            ['Payment is required to enable this community. Please visit the Billing screen to make a payment.'],
            'danger'
        );
    }

    let title = (formState === 'edit') ?
        <h3 className="page-title">Editing: {c ? c.name : ''}</h3> :
        <h3 className="page-title">Create New Community</h3>;

    return (
        <Container className="dashboard">
            <Row>
                <Col sm={12}>{title}</Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Alert alertKey={c ? c.slug : 'communityEdit'} />
                    {
                        c ? <CommunityDetailForm communityData={c} formState={formState}/>
                          : <Spinner height="400px" />
                    }
                    {
                        formState=='create' ? <></> : c ? <CommunityPlacesForm communityData={c} formState={formState}/>
                            : <Spinner height="200px" />
                    }
                    {
                        formState=='create' ? <></> : c ? <CommunityAdminsForm communityData={c} formState={formState}/>
                            : <Spinner height="200px" />
                    }
                </Col>
            </Row>
        </Container>
    );
};


CommunityEdit.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    communities: state.community.communities,
});

export default connect(mapStateToProps, { setAlert, removeAlert })(CommunityEdit);

