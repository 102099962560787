import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import { convertToDate, convertISOtoDateInput, convertISOtoTimeInput, filterEntity, lastItem, rteModules } from '../../services/FormService';
import ImageUpload from '../../shared/ImageUpload';
import PlaceSelector from '../../shared/PlaceSelector';

import { Row, Col, Card, CardBody } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityEvents } from '../../redux/actions/events';
import { getCommunities } from '../../redux/actions/community';
import { apiRoot } from '../../services/Helpers';

import PlacesAutocomplete from 'react-places-autocomplete';

const EventEditForm = (props: any) => {
    const { events, event, isNew, communityId, communities, communityData, getCommunityEvents, getCommunities, setAlert, removeAlert, redirect } = props;

    let initialDates = event.dates.map( (date:any) => {
        const thisDate = {
            'date': convertISOtoDateInput(date.start),
            'start': convertISOtoTimeInput(date.start),
            'end': convertISOtoTimeInput(date.end)
        };
        return thisDate;
    });
    const initialOtherPlace = event.location.type === 'otherPlace' ? event.location.places[0] : { _id: null, name: null, formatted_address: null };
    const initialPlaces = event.location.type === 'place' ? event.location.places.map( (p:any) => p._id ) : [];

    const [doRedirect, setDoRedirect] = useState( redirect );
    const [imgPath, setImgPath] = useState( event.image );
    const [rteData, setRteData] = useState( event.content );
    const [eventSlug, setEventSlug] = useState( event.slug );
    const [isSaving, setIsSaving] = useState(false );
    const [saveOk, setSaveOk] = useState(false );
    const [locationType, setLocationType] = useState( event.location.type );
    const [selectedPlaces, setSelectedPlaces] = useState( initialPlaces );
    const [address, setAddress] = useState('');
    const [otherPlace, setOtherPlace] = useState( initialOtherPlace );
    const [eventDates, setEventDates] = useState( initialDates );

    // let localtime = convertToLocal(event.publishDate);

    let c = communityData;

    let { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
            title: event.title,
            subtitle: event.subtitle,
            'location.url': event.location.url,
            'location.text': event.location.text,
        }
    });

    const refreshImage = ( newImgPath: string ) => {
        setImgPath( newImgPath );
    };

    const updateContent = ( html: string ) => {
        setRteData( html );
    };

    const placeClicked = ( pid: string, ev: any ) => {
        let placesChecked = [...selectedPlaces];
        const checked = ev.target.checked;

        if (checked && !placesChecked.includes( pid )) {
            placesChecked.push( pid );
        } else if (!checked) {
            placesChecked = placesChecked.filter((p:any) => p !== pid);
        }
        setSelectedPlaces( placesChecked );
    };

    const changeLocationType = ( e:any, newval:string ) => {
        e.preventDefault();
        setLocationType(newval);
    };

    const searchOptions = {
        location: new google.maps.LatLng(40.451159, -79.933399),
        radius: 50,
        types: ["establishment"]
    };

    const handleChange = (address: string) => {
        setAddress( address );
    };

    const resetOtherPlace = () => {
        setOtherPlace( { _id: null, name: null, formatted_address: null } );
    };

    const addDate = () => {
        let newDate = lastItem(eventDates);
        let tmpDates = [...eventDates];
        tmpDates.push( newDate );
        setEventDates(tmpDates);
    };

    const deleteDate = ( e:any, i:number ) => {
        e.preventDefault();
        let tmpDates = [...eventDates];
        tmpDates.splice( i, 1 );
        setEventDates(tmpDates);
    };

    const handleSelect = async (address: string, placeId: string) => {
        if (placeId) {
            try {
                const res = await axios.get(apiRoot()+'/places/'+placeId );
                if (res.data.success == true) {
                    setOtherPlace(res.data.payload);
                }
            } catch(err) {
                console.log('Error getting place data', err);
            }
        }
    };

    const handleEventDateChange = ( event:any, i:number ) => {
        let tmpEventDates = eventDates.map( (d:any, j:number) => {
            if (i == j) {
                let e = {
                    date: event.target.name == 'eDate' ? event.target.value : d.date,
                    start: event.target.name == 'eStart' ? event.target.value : d.start,
                    end: event.target.name == 'eEnd' ? event.target.value : d.end,
                };
                return e;
            } else return d;
        });
        setEventDates(tmpEventDates);
    };

    const onSubmit = async ( data: any ) => {
        let tmpDates = eventDates.map((d:any) => {
            return {
                start: convertToDate( d.date, d.start ),
                end: convertToDate( d.date, d.end )
            }
        });
        let sortedDates = tmpDates.sort((a:any, b:any) => {
            let adate:any = new Date(a.start);
            let bdate:any = new Date(b.start);
            return adate - bdate;
        });

        data.location = { type: null, places: [] };
        data.dates = sortedDates;
        data.content = rteData;
        data.communityId = communityId;
        data.image = imgPath;
        data.location.type = locationType;

        if (!isNew) data.id = event._id;

        if (locationType === 'place' || locationType === 'otherPlace') {
            data.location = {};
            data.location.type = locationType;
            data.location.places = locationType === 'place' ? selectedPlaces : locationType === 'otherPlace' ? [otherPlace._id] : null;
        } else {
            data.location.type = locationType;
        }

        try {
            let res: any;
            if (isNew) { res = await axios.post(apiRoot()+'/events/create', data); }
            else {       res = await axios.post(apiRoot()+'/events/update', data); }

            if (res.data.success) {
                getCommunityEvents({
                    communityId: data.communityId,
                    page: events.page,
                    limit: events.limit
                });
                if (isNew) setAlert('eventEdit', 'Event created successfully', [data.title], 'success');  //key, title, errors, alertType
                if (res.data.payload.slug !== event.slug) {
                    setEventSlug( res.data.payload.slug );
                    setDoRedirect(true);
                }
            }

        } catch(err) {
            // TODO: Replace with UI Error Report
            console.log('API Error while saving profile', err);
        }

        setIsSaving(false);
        setSaveOk(true);
        setTimeout(function(){ setSaveOk(false); },5000);    };

    return (
        doRedirect ?
            <Redirect to={`/refresher/events/edit/${eventSlug}`} />
            :
        <Card>
            <CardBody>
                <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={6}>
                            <div className="form-group">
                                <label className="req">{ errors.title && <span>Required</span> }Title</label>
                                <input type="text" name="title" className={`form-control`}
                                        ref={register({ required: true })}/>
                            </div>
                            <div className="form-group">
                                <label>Subtitle</label>
                                <input type="text" name="subtitle" className={`form-control`}
                                        ref={register}/>
                            </div>
                            <div className="form-group">
                                    <br/>
                                    <label className="req">Event Location:</label>
                                    <div className={`place-options`}>
                                        <a href="#" className={locationType == 'place' ? `on` : ``} onClick={(event)=>changeLocationType(event, 'place')}>My Places</a> &nbsp; | &nbsp;
                                        <a href="#" className={locationType == 'otherPlace' ? `on` : ``} onClick={(event) => changeLocationType(event, 'otherPlace')}>Another Place</a> &nbsp; | &nbsp;
                                        <a href="#" className={locationType == 'url' ? `on` : ``} onClick={(event)=>changeLocationType(event, 'url')}>A Website</a> &nbsp; | &nbsp;
                                        <a href="#" className={locationType == 'other' ? `on` : ``} onClick={(event)=>changeLocationType(event, 'other')}>Free Text</a>
                                    </div>
                                    {locationType == 'place' &&
                                        <PlaceSelector communities={communities} communityId={communityId} selectedPlaces={selectedPlaces} onPlaceClick={placeClicked}></PlaceSelector>
                                    }
                                    {locationType == 'url' &&
                                        <>
                                        <input type="text" name="location.url" className={`form-control`}
                                               ref={register({ pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ })}
                                               placeholder="e.g.: http://example.com" />
                                        </>
                                    }
                                    {locationType == 'other' &&
                                        <input type="text" name="location.text" className={`form-control`} ref={register} placeholder="e.g.: All participating locations"/>
                                    }
                                    {(locationType == 'otherPlace' && otherPlace._id === null) &&
                                    <PlacesAutocomplete
                                      value={address}
                                      onChange={handleChange}
                                      onSelect={handleSelect}
                                      searchOptions={searchOptions}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className={`admin-search`}>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Search Places ...',
                                                        className: 'location-search-input form-control',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    }
                                    {(locationType == 'otherPlace' && otherPlace._id) &&
                                        <div className='event-place'>
                                          <div className='icon'><i className="fas fa-map-marker-alt"></i></div>
                                          <div className='place'>
                                              <b>{otherPlace.name}</b><br/>
                                              {otherPlace.formatted_address}
                                          </div>
                                          <div className='reset' onClick={()=>resetOtherPlace()}>
                                            <i className="fas fa-times-circle"></i>
                                          </div>
                                        </div>
                                    }
                                </div>
                            <div className="form-group">
                                <br/>
                                <label className="req">Event Dates</label>
                                <div className="event-dates">
                                    <div className="date-instances">
                                        {
                                            eventDates.map((date:any, i:number) => {
                                                // let start = convertToLocal( date.starts );
                                                // let end = convertToLocal( date.end );
                                                return (
                                                    <div className="date-instance" key={'date-'+i}>
                                                        <div className="startDate">
                                                            <input type="date" className="form-control" value={ date.date } name="eDate" onChange={(e)=>handleEventDateChange(e, i)}/>
                                                        </div>
                                                        <div className="startTime">
                                                            <input type="time" className="form-control" value={ date.start } name="eStart" onChange={(e)=>handleEventDateChange(e, i)} />
                                                        </div>
                                                        <div className="to">to</div>
                                                        <div className="endTime">
                                                            <input type="time" className="form-control" value={ date.end } name="eEnd" onChange={(e)=>handleEventDateChange(e, i)} />
                                                        </div>
                                                        <div className='reset'>
                                                            { i >= 1 && <i className="fas fa-times-circle" onClick={(e) => deleteDate(e, i)}></i> }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="addDate" onClick={()=>addDate()}><i className="fas fa-plus-circle"></i> Add Date</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="form-group">
                                <ImageUpload label="Event Image" onUpload={refreshImage} imageTarget="events/image" currentImage={imgPath} targetId={isNew ? 'new' : event._id} />
                            </div>
                            <div className="form-group">
                                <label>Event Description</label>
                                <ReactQuill value={rteData || ''} onChange={(html: string) => updateContent(html)}
                                            modules={rteModules}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <br/>
                            <div className={`form-group saving-button text-center`}>
                                <i className="fad fa-hurricane fa-fw"></i>
                                <button type={`submit`} className={`btn btn-primary ` + (isSaving ? `disabled` : '')} disabled={isSaving}>{isNew ? 'Save and Continue' : 'Save Changes'}</button>
                                <i className={`fa-fw ` + (isSaving ? `fad fa-hurricane fa-spin active` : saveOk ? 'far fa-check-circle active' : 'fad fa-hurricane')}></i>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    );
}


EventEditForm.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityEvents: PropTypes.func.isRequired,
    getCommunities: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    events: state.events,
    communities: state.community.communities
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunities, getCommunityEvents })(EventEditForm);


