import React, {useState, useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'

import axios from 'axios';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../redux/actions/profile';
import { apiRoot } from "../services/Helpers";

export const ImageUpload = ( props: any ) => {

    const { label, imageTarget, currentImage, targetId, getCurrentProfile } = props;

    const [uploading, setUploading] = useState( false );
    const [uploadPath, setUploadPath] = useState( '' );

    let cropWidth: number, cropHeight: number, uploadTarget: string, uploadType: string, uploadId: string;

    switch (imageTarget) {
        case 'user/avatars':
            cropWidth = 400;
            cropHeight = 400;
            uploadTarget = 'user';
            uploadType = 'avatar';
            uploadId = targetId;
            break;
        case 'community/avatars':
            cropWidth = 400;
            cropHeight = 400;
            uploadTarget = 'community';
            uploadType = 'avatar';
            uploadId = targetId;
            break;
        case 'community/banners':
            cropWidth = 1000;
            cropHeight = 500;
            uploadTarget = 'community';
            uploadType = 'banner';
            uploadId = targetId;
            break;
        case 'news/image':
            cropWidth = 1000;
            cropHeight = 500;
            uploadTarget = 'news';
            uploadType = 'image';
            uploadId = targetId;
            break;
        case 'events/image':
            cropWidth = 1000;
            cropHeight = 500;
            uploadTarget = 'events';
            uploadType = 'image';
            uploadId = targetId;
            break;    
        case 'deals/image':
            cropWidth = 1000;
            cropHeight = 500;
            uploadTarget = 'deals';
            uploadType = 'image';
            uploadId = targetId;
            break;
        default:
            cropWidth = 100;
            cropHeight = 100;
            uploadTarget = 'none';
            uploadType = 'none';
            uploadId = '';
            break;
    }

    const initFile:any = null;
    const [uploadFile, setUploadFile] = useState( initFile );

    const onDrop = useCallback(acceptedFiles => {
        setUploadFile( acceptedFiles[0] );
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


    async function handleUpload() {
        setUploading(true);

        const options = { headers: { "Content-Type": "multipart/form-data" } };
        const data = new FormData();

        let fileParts = uploadFile.name.split('.');
        let fileName = fileParts[ 0 ];
        let fileType = fileParts[ 1 ];

        data.append('cropWidth', cropWidth.toString());
        data.append('cropHeight', cropHeight.toString());
        data.append('fileName', fileName);
        data.append('fileType', fileType);
        data.append('file', uploadFile);

        const res = await axios.post(apiRoot()+"/images/" + uploadTarget + "/" + uploadType + "/" + targetId, data, options);

        if (res.data.success) {
            switch (uploadTarget) {
                case 'user':
                    getCurrentProfile(); break;
                case 'community':
                case 'news':
                case 'deals':
                case 'events':                    
                    setUploadPath( res.data.imagePath ); break;
                default: break;
            }
            setUploading(false);
            setUploadFile( null );
        }
    }

    useEffect( () => {
        if ( uploadFile !== null ) {
            handleUpload();
        }
    }, [uploadFile]);

    return (
        <div className="auth-form">
        <div className="form-group">
            <label><span className={`gray`}>({ cropWidth }x{cropHeight})</span>{ label }</label>


            <label htmlFor="file-to-upload" className={"image-preview" + ( isDragActive ? ' drag-hover' : '')} {...getRootProps()}>
                <img className="form-image" src={`https://commet.s3.amazonaws.com/` + (uploadPath.length > 0 ? uploadPath : currentImage ) } />
                { uploading && <div className="img-uploading"></div> }
            </label>


            <div className={`image-upload`}>
                {/*onChange={() => handleChange(event)}*/}
                <input type="file" id="file-to-upload" {...getInputProps()}/>
                <button className="btn btn-primary" onClick={() => handleUpload()} disabled={!uploadFile}><i className="fas fa-arrow-up"></i>{ uploadFile ? ' Upload' : '' }</button>
                <span className={uploadFile ? 'small' : 'wide'}>{ uploadFile ? uploadFile.name : 'Upload New Image' }</span>
            </div>
        </div>
        </div>
    )
};

ImageUpload.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentProfile })(ImageUpload);

