import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import axios from 'axios';
import { connect } from 'react-redux';
import { getCommunities } from '../../redux/actions/community';
import { apiRoot } from "../../services/Helpers";
import { openModal, closeModal } from '../../redux/actions/modal';

export const CommunityPlacesForm = (props: any) => {

    const { communityData, formState, modal, openModal, closeModal, getCommunities, auth } = props;

    const [address, setAddress] = useState('');
    const [isAddingBeacon, setIsAddingBeacon] = useState(false);

    let c = communityData;

    const searchOptions = {
        location: new google.maps.LatLng(40.451159, -79.933399),
        radius: 50,
        types: ["establishment"]
    };

    const handleChange = (address: string) => {
        setAddress(address);
    };

    const handleSelect = async (address: string, placeId: string) => {
        if (placeId) {
            try {
                const res = await axios.get(apiRoot() + '/places/' + placeId);
                if (res.data.success == true) {
                    // Add Place to Community
                    const newPlaceId = res.data.payload._id;
                    const data = {
                        placeId: res.data.payload._id,
                        communityId: c._id
                    };
                    const addPid = await axios.post(apiRoot() + '/community/place/add', data);
                    setAddress('');
                    getCommunities();
                }
            } catch (err) {
                console.log('Error getting place data', err);
            }
        }
    };

    const removePlaceFromCommunity = async (placeId: string, communityId: string, e: any) => {
        e.preventDefault();
        const response = confirm("Are you sure you remove this place from this community?");
        if (response) {
            const data = { placeId: placeId, communityId: communityId };
            const res = await axios.post(apiRoot() + `/community/place/remove`, data);
            if (res.data.success === true) {
                getCommunities();
            }
        }
    };

    const addBeacon = async (cId: string, pId: string) => {
        const yesno = confirm('Are you sure you want to add a beacon to this community place?');
        if (yesno) {
            setIsAddingBeacon(true);
            const beaconData = { communityId: cId, placeId: pId };
            const res = await axios.post( apiRoot()+'/community/beacon', beaconData);

            if (res.data.success) {
                getCommunities();
                const modalData = {
                    '_id': res.data.payload.beaconId,
                    major: res.data.payload.major,
                    minor: res.data.payload.minor
                }
                openModal('beacon', modalData);
            }
            setIsAddingBeacon(false);
        }
    }

    const deleteBeacon = async ( cId:string, bId:string ) => {
        const yesno = confirm('Are you sure you want to delete this beacon?');
        if (yesno) {
            const res = await axios.delete( apiRoot()+'/community/beacon/'+cId+'/'+bId);

            if (res.data.success) {
                getCommunities();
            }
        }
    }

    return (
        <Card>
            <CardHeader><i className="fad fa-map-marker-alt fa-fw red"></i> &nbsp;Community Places</CardHeader>
            <CardBody>
                <Row>
                    <Col sm={12}>
                        <div className={`card-info`}>If there are physical locations associated with your community -
                            like a place of business, office or venue - add them here.
                        </div>

                        <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            searchOptions={searchOptions}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className={`admin-search`}>
                                    <label>Search Places:</label>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input form-control',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>

                        {c.places.length ?
                            <div>
                                <div className={`community-admins small`}></div>
                                <h4>Registered Places:</h4>
                                <div className={`community-admins`}>
                                    {c.places.map((place: any) =>
                                        <div className={`community-admin`} key={place._id}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <b>{place.name}</b>
                                            <br/>{place.formatted_address.replace(', USA', '')}
                                            <div>
                                                <a href={`#`} onClick={() => { removePlaceFromCommunity(place._id, c._id, event) }}>
                                                    <i className={`far fa-times-circle`}></i> Remove
                                                </a>
                                            </div>
                                            {auth && auth.user && auth.user.isAdmin ?
                                                <>
                                                <div className='place-beacons'>
                                                    { c.beacons.filter( (b:any) => b.place === place._id ).length === 0 &&
                                                        <button className="beacon-btn"
                                                                onClick={() => addBeacon(c._id, place._id)}
                                                                disabled={isAddingBeacon}>{isAddingBeacon ?
                                                            <i className="fas fa-spin fa-spinner"></i> : '+ Add'}
                                                        </button>
                                                    }
                                                    <b>Beacons:</b>
                                                </div>
                                                    <div className='beacon-list'>
                                                    {c.beacons.map((b: any, i: number) => b.place === place._id &&
                                                        <div className="beacon-list-item" key={b._id}>
                                                            <div onClick={() => openModal('beacon', b)}>
                                                                <i className="fad fa-signal-stream"></i>&nbsp;&nbsp;<b>{b.major}.{b.minor}</b>&nbsp;&nbsp;
                                                            </div>
                                                            <div><i className="fas fa-times-circle"
                                                                    onClick={()=>deleteBeacon(c._id, b._id)}></i></div>
                                                        </div>
                                                    )}
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div> : <></>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

}

CommunityPlacesForm.propTypes = {
    getCommunities: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    modal: state.modal
});


export default connect(mapStateToProps, { getCommunities, openModal, closeModal })(CommunityPlacesForm);


// BEACON UUID: 768f51b6-9434-4c4e-b2b2-01fe49fdd958
