import axios from 'axios';

import {GET_COMMUNITY_DEALS, CLEAR_COMMUNITY_DEALS, COMMUNITY_DEALS_LOADING} from "../types";
import { apiRoot } from "../../services/Helpers";

export const getCommunityDeals = ( communityData ) => async dispatch => {
  try {
    const res = await axios.post(apiRoot()+'/deals/community', communityData);
    dispatch({
      type: COMMUNITY_DEALS_LOADING,
      payload: true
    });
    dispatch({
      type: GET_COMMUNITY_DEALS,
      payload: res.data.payload
    });
  } catch(err) {
    console.log('dealsload',err);
  }
};

export const deleteDeal = ( dealData ) => async dispatch => {
  try {
    await axios.post(apiRoot()+'/deals/delete/deal', dealData);
    dispatch(getCommunityDeals({
      communityId: dealData.communityId,
      page: dealData.page,
      limit: dealData.limit
    }));
  } catch(err) {
    console.log('deals delete error',err);
  }
};


export const clearCommunityDeals = () => async dispatch => {
  dispatch({
    type: CLEAR_COMMUNITY_DEALS,
    payload: null
  })
};
