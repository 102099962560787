import React, { useEffect } from 'react';
import moment from 'moment';

export const generateSlug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
};

export const formError = (str: string) => {
    return <p className={`form-error red`}><i className="fas fa-exclamation-triangle"></i> &nbsp;{str}</p>;
};

export const filterEntity = (entitySet: any, filterBy: string, searchValue: string) => {
    let x = entitySet.filter((c: any) => {
        return c[ filterBy ] === searchValue
    });
    return x.length > 0 ? x[ 0 ] : null;
};

export const communityLocked = ( activeCommunity: string, communityData:any ) => {
    const thisCommunity = filterEntity( communityData, '_id', activeCommunity);
    return thisCommunity ? !thisCommunity.active : true;

};

export const rteModules = { toolbar: [['bold', 'italic', 'underline', 'strike'], ['link'], ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], [{ 'script': 'sub'}, { 'script': 'super' }], ['clean']] };

export const googleApiKey = "AIzaSyAxszIVXrizMQWsxphz6XopktLG1j9QbAE";

// export const placeSearchOptions = {
//     location: new google.maps.LatLng(40.451159, -79.933399),
//     radius: 50,
//     types: ["address","establishment"]
// };


export const mapClass = ( value: number, otherError: boolean ) => {
    let classOut = '';
    switch (value) {
        case -1: classOut = ''; break;
        case 0:  classOut = 'loading'; break;
        case 1:  classOut = 'good'; break;
        case 2:  classOut = 'bad'; break;
        default: classOut = ''; break;
    }
    return otherError ? 'bad' : classOut;
};

export const checkProfileComplete = (p: any) => {
    if ((!p.firstName || p.firstName.length == 0) || (!p.lastName || p.lastName.length == 0) || !p.birthDate || !p.gender) {
        return false;
    } else {
        return true;
    }
};

export const convertToDate = ( date: string, time: string ) => {
    //2020-01-29 17:27
    let d = date.split('-');
    let t = time.split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    return newDate.toUTCString();
};

export const convertToUnix = ( date: string, time: string ) => {
    //2020-01-29 17:27
    let d = date.split('-');
    let t = time.split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    return Math.round(newDate.getTime() / 1000);
};

export const convertToLocal = ( date: string ) => {
    //2020-02-02T17:20:00.000Z
    let d = date.substr(0,10).split('-');
    let t = date.substr(11,5).split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    const offset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offset);
    return newDate.toString();
}

export const convertToLocalISO = ( date: string ) => {
    //2020-02-02T17:20:00.000Z
    let d = date.substr(0,10).split('-');
    let t = date.substr(11,5).split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    const offset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offset);
}

export const convertISOtoDateInput = ( dateIn: string ) => {
    //2020-02-02T17:20:00.000Z
    let d = dateIn.substr(0,10).split('-');
    let t = dateIn.substr(11,5).split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    const offset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offset);
    const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const [{ value: mo },,{ value: da },,{ value: ye }] = dtf.formatToParts(newDate);
    let out = ye+'-'+mo+'-'+da;
    return out;
}

export const convertISOtoTimeInput = ( dateIn: string ) => {
    //2020-02-02T17:20:00.000Z
    let d = dateIn.substr(0,10).split('-');
    let t = dateIn.substr(11,5).split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    const offset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offset);
    let out = moment(newDate).format('HH:mm');
    return out;
}

export const lastItem = (array: any ) => {
    if (array == null)
        return void 0;
    else
        return array[array.length - 1];
};

