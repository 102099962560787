import { combineReducers } from 'redux';
import alert from './reducers/alert';
import auth from './reducers/auth';
import profile from './reducers/profile';
import community from './reducers/community';
import news from './reducers/news';
import deals from './reducers/deals';
import events from './reducers/events';
import modal from './reducers/modal';

export default combineReducers({
    alert, auth, profile, community, news, deals, events, modal
});
