import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeModal } from '../redux/actions/modal';


const CmtModal = (props: any) => {

    const { closeModal, modal } = props;

    const closeTheModal = () => { closeModal(); };

    const modalType = modal && modal.modalType && modal.modalType ? modal.modalType : 'normal';

    return (
        <div className={'cmt-modal' + (modal.open ? ' open' : '') + ' ' + modal.modalSize}>
            <div className="cmt-modal-wrap">
                <div className={"cmt-modal-body " + modalType}>
                        <div className="cmt-modal-content">
                            { modal && modal.modalType && modal.modalType === 'beacon' &&
                                <div>
                                    <h3>Beacon Data</h3>
                                    <table>
                                        <tbody>
                                        <tr><td>Range UUID:</td><td>F7826DA6-4FA2-4E98-8024-BC5B71E0893E</td></tr>
                                        <tr><td>Beacon ID:</td><td>{ modal.modalData._id }</td></tr>
                                        <tr><td>Major:</td><td>{ modal.modalData.major }</td></tr>
                                        <tr><td>Minor:</td><td>{ modal.modalData.minor }</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    <div className="cmt-modal-close" onClick={() => closeTheModal()}>
                        <i className="fas fa-circle"></i>
                        <i className="far fa-times-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};



CmtModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
};
const mapStateToProps = (state: any) => ({
    modal: state.modal,
});

export default connect(mapStateToProps, { closeModal })(CmtModal);

