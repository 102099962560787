import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import { convertToDate, convertToLocal, rteModules } from '../../services/FormService';
import ImageUpload from '../../shared/ImageUpload';
import PlaceSelector from '../../shared/PlaceSelector';

import { Row, Col, Card, CardBody } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityDeals } from '../../redux/actions/deals';
import { apiRoot } from '../../services/Helpers';

const DealsEditForm = (props: any) => {

    const { deals, deal, isNew, communityId, communities, getCommunityDeals, setAlert, removeAlert, redirect } = props;

    const initialPlaces = deal.location.type === 'place' ? deal.location.places.map( (p:any) => p._id ) : [];

    const [doRedirect, setDoRedirect] = useState( redirect );
    const [imgPath, setImgPath] = useState( deal.image );
    const [rteData, setRteData] = useState( deal.content );
    const [dealSlug, setDealSlug] = useState( deal.slug );
    const [isSaving, setIsSaving] = useState(false );
    const [saveOk, setSaveOk] = useState(false );
    const [locationType, setLocationType] = useState( deal.location.type );
    const [selectedPlaces, setSelectedPlaces] = useState( initialPlaces );

    let localtime = convertToLocal(deal.dealStarts);

    let { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
            title: deal.title,
            subtitle: deal.subtitle,
            'location.url': deal.location.url,
            'location.text': deal.location.text,
            dealStarts: deal.dealStarts.substr(0,10),
            dealEnds: deal.dealEnds.substr(0,10),
        }
    });

    const refreshImage = ( newImgPath: string ) => {
        setImgPath( newImgPath );
    };

    const updateContent = ( html: string ) => {
        setRteData( html );
    };

    const placeClicked = ( pid: string, ev: any ) => {
        let placesChecked = [...selectedPlaces];
        const checked = ev.target.checked;
        if (checked && !placesChecked.includes( pid )) {
            placesChecked.push( pid );
        } else if (!checked) {
            placesChecked = placesChecked.filter((p:any) => p !== pid);
        }
        setSelectedPlaces( placesChecked );
    };

    const changeLocationType = ( e:any, newval:string ) => {
        setLocationType(newval);
        return false;
    }

    const onSubmit = async ( data: any ) => {

        setIsSaving(true);

        if (!isNew) data.id = deal._id;
        data.location = { type: null, places: [] };
        data.content = rteData;
        data.communityId = communityId;
        data.image = imgPath;
        data.dealStarts = convertToDate( data.dealStarts, '00:00' );
        data.dealEnds = convertToDate( data.dealEnds, '23:59' );
        data.location.type = locationType;

        if (locationType === 'place') {
            data.location = { type: null, places: [] };
            data.location.type = locationType;
            data.location.places = locationType === 'place' ? selectedPlaces : null;
        }

        try {
            let res: any;
            if (isNew) { res = await axios.post(apiRoot()+'/deals/create', data); }
            else {       res = await axios.post(apiRoot()+'/deals/update', data); }

            if (res.data.success) {
                getCommunityDeals({
                    communityId: data.communityId,
                    page: deals.page,
                    limit: deals.limit
                });
                if (isNew) setAlert('dealsEdit', 'Deal created successfully', [data.title], 'success');  //key, title, errors, alertType
                if (res.data.payload.slug !== deal.slug) {
                    setDealSlug( res.data.payload.slug );
                    setDoRedirect(true);
                }
            }

        } catch(err) {
            // TODO: Replace with UI Error Report
            console.log('API Error while saving profile', err);
        }

        setIsSaving(false);
        setSaveOk(true);
        setTimeout(function(){ setSaveOk(false); },5000);
    };

    return (
        doRedirect ?
            <Redirect to={`/refresher/deals/edit/${dealSlug}`} />
            :
            <Card>
                <CardBody>
                    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={6}>
                                <div className="form-group">
                                    <label className="req">{ errors.title && <span>Required</span> }Title</label>
                                    <input type="text" name="title" className={`form-control`}
                                           ref={register({ required: true })}/>
                                </div>
                                <div className="form-group">
                                    <label>{ errors.subtitle && <span>Required</span> }Subtitle</label>
                                    <input type="text" name="subtitle" className={`form-control`}
                                           ref={register}/>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label className="req">{errors.dealStarts && <span>Required</span>}Deal Starts</label>
                                            <input type="date" name="dealStarts" className={`form-control`}
                                                   ref={register({ required: true })}/>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label className="req">{errors.dealEnds && <span>Required</span>}Deal Ends</label>
                                            <input type="date" name="dealEnds" className={`form-control`}
                                                   ref={register({ required: true })}/>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="form-group">
                                    <br/>
                                    <label className="req">Redeemable at: { locationType }</label>
                                    <div className={`place-options`}>
                                        <a href="#" className={locationType == 'place' ? `on` : ``} onClick={()=>changeLocationType(event, 'place')}>A Place</a> &nbsp; | &nbsp;
                                        <a href="#" className={locationType == 'url' ? `on` : ``} onClick={()=>changeLocationType(event, 'url')}>A Website</a> &nbsp; | &nbsp;
                                        <a href="#" className={locationType == 'other' ? `on` : ``} onClick={()=>changeLocationType(event, 'other')}>Free Text</a>
                                    </div>
                                    {locationType == 'place' &&
                                        <PlaceSelector communities={communities} communityId={communityId} selectedPlaces={selectedPlaces} onPlaceClick={placeClicked}></PlaceSelector>
                                    }
                                    {locationType == 'url' &&
                                        <>
                                        <input type="text" name="location.url" className={`form-control`}
                                               ref={register({ pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ })}
                                               placeholder="e.g.: http://example.com" />
                                        </>
                                    }
                                    {locationType == 'other' &&
                                        <input type="text" name="location.text" className={`form-control`} ref={register} placeholder="e.g.: All participating locations"/>
                                    }
                                </div>

                            </Col>
                            <Col sm={6}>
                                <div className="form-group">
                                    <ImageUpload label="Deal Photo" onUpload={refreshImage} imageTarget="deals/image" currentImage={imgPath} targetId={isNew ? 'new' : deal._id} />
                                </div>
                                <div className="form-group">
                                    <label>Deal Details</label>
                                    <ReactQuill value={rteData || ''} onChange={(html: string) => updateContent(html)}
                                                modules={rteModules}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <br/>
                                <div className={`form-group saving-button text-center`}>
                                    <i className="fad fa-hurricane fa-fw"></i>
                                    <button type={`submit`} className={`btn btn-primary ` + (isSaving ? `disabled` : '')} disabled={isSaving}>{isNew ? 'Save and Continue' : 'Save Changes'}</button>
                                    <i className={`fa-fw ` + (isSaving ? `fad fa-hurricane fa-spin active` : saveOk ? 'far fa-check-circle active' : 'fad fa-hurricane')}></i>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
    );
};



DealsEditForm.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityDeals: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    deals: state.deals,
    communities: state.community.communities
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityDeals })(DealsEditForm);


