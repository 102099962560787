import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Container, Row, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';


const CommunitiesList = (props: any) => {

    const { communities } = props;

    const ttDelay = { show: 250, hide: 600 };

    return (
        <Container className="dashboard">
            <Row>
                <Col md={8}>
                    <h3 className="page-title">Communities</h3>
                </Col>
                <Col md={4} className={`text-right`}>
                    <Link className="btn btn-sm btn-pill btn-success" to={`/communities/new`}><i
                        className="fad fa-plus-circle fa-lg"></i> &nbsp;New Community</Link>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>

                    <Card>
                      <CardHeader><i className="fad fa-users red"></i> &nbsp;Communities You Manage</CardHeader>
                      <CardBody>
                        <table className="table table-hover no-top-border">
                          <thead>
                          <tr>
                            <th>Community Name</th>
                            <th>Type</th>
                            <th className="center-cell">Members</th>
                            <th className="center-cell">Active</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>{
                              communities && communities.map((c: any) => {
                                  return (
                                      <tr key={c.slug}>
                                          <td>{c.name}</td>
                                          <td>{c.communityType.name}</td>
                                          <td className="center-cell">{c.counts.users}</td>
                                          <td className="center-cell">{c.active ?
                                                <i className="far fa-check fa-lg fa-fw green"></i> :
                                              <div>
                                                <i className="far fa-times-circle fa-lg fa-fw red" id={`tt-`+c.slug}></i>
                                                  <UncontrolledTooltip placement="top" target={`tt-`+c.slug} delay={ttDelay}>
                                                      Community requires payment. Visit the billing page.
                                                  </UncontrolledTooltip>
                                              </div>
                                          }</td>
                                          <td><Link className="btn btn-sm btn-primary" to={`/communities/edit/` + c.slug}>Edit</Link></td>
                                      </tr>
                                  )
                              })
                          }
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}


CommunitiesList.propTypes = {
    communities: PropTypes.array.isRequired,
};

const mapStateToProps = (state: any) => ({
    communities: state.community.communities,
});

export default connect(mapStateToProps)(CommunitiesList);

