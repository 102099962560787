import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import { formError, rteModules } from '../../services/FormService';
import axios from 'axios';
import ImageUpload from '../../shared/ImageUpload';
import { getCommunities } from '../../redux/actions/community';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { apiRoot } from "../../services/Helpers";

export const CommunityDetailForm = (props: any) => {

    const { communityData, formState, auth, getCommunities } = props;
    let c = communityData;

    const [communityNameOK, setCommunityNameOK] = useState('ok');
    const [communitySlug, setCommunitySlug] = useState(c.slug);
    const [isSaving, setIsSaving] = useState(false );
    const [saveOk, setSaveOk] = useState(false );
    const [redirect, setRedirect] = useState(false );
    const [rteData, setRteData] = useState( c.description );

    const isAdmin = (auth && auth.user && auth.user.isAdmin) ? true : false;
    const isNew = formState==="create";

    function setValue(value: any) {
        return isNew ? '' : value;
    }

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            name: setValue(c.name),
            slug: setValue(c.slug),
            communityType: setValue(c.communityType._id),
            email: setValue(c.email),
            phone: setValue(c.phone),
        }
    });

    const updateDescription = ( html: string ) => {
        setRteData( html );
    };

    const onSubmit = async (data: any) => {
        setIsSaving(true);

        data._id = !isNew ? c._id : null;
        data.slug = communitySlug;
        data.description = rteData;

        try {
            let res:any;
            if (isNew) { res = await axios.post(apiRoot()+'/community/create', data); }
            else {       res = await axios.post(apiRoot()+'/community/update', data); }

            if (res.data.success) {
                // refresh communities
                console.log('refreshing communities');
                getCommunities();
                if (data.slug !== c.slug) {
                    setRedirect(true);
                }
            }

        } catch(err) {
            console.log('API Error while saving profile', err);
        }

        setIsSaving(false);
        setSaveOk(true);
        setTimeout(function(){ setSaveOk(false); },5000);
    };

    const onImageUpload = ( imgpath:string ) => {

    }


    return (
        redirect ? <Redirect to={`/communities/edit/`+communitySlug} /> :
        <Card>
            <CardHeader><i className="fad fa-list fa-fw red"></i> &nbsp;Community Details</CardHeader>
            <CardBody>
                <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={6}>
                        <div className="form-group">
                            <label>Community Name</label>
                            <input type="text" name="name"
                                   className={`form-control ${communityNameOK}`}
                                   onChange={e => checkCommunityName(e.target.value)}
                                   ref={register({ required: true, minLength: 3, maxLength: 80 })}/>
                            {errors.name && errors.name.type === 'required' && (formError('Community Name is required'))}
                            {errors.name && errors.name.type === 'minLength' && (formError('Community Name must be at least 3 characters'))}
                            <input type="hidden" name="slug" value={communitySlug}/>
                        </div>
                        <div className="form-group">
                            <label>Community Type</label>
                            <select name="communityType" className="form-control"
                                    ref={register({ required: true })}>
                                <option value="5e1b51611c9d440000d65fc0">Free (100 Member Limit)</option>
                                <option value="5e1b51ab1c9d440000d65fc1">Social ($9.99 / month)</option>
                                <option value="5e1b51db1c9d440000d65fc2">Business ($19.99 / month)</option>
                                {isAdmin ? <option value="5e3712b21c9d44000072abda">Reserved (Admin Only)</option> : <></>}
                            </select>
                        </div>
                        {!isNew &&
                        <div className="form-group">
                          <label>Community Description</label>
                          <ReactQuill value={rteData || ''} onChange={(html: string) => updateDescription(html)}
                                      modules={rteModules}/>
                        </div>
                        }
                    </Col>
                    <Col sm={6}>
                        <div className="form-group">
                            <label>Contact Email</label>
                            <input type="email" name="email"
                                   className="form-control"
                                   ref={register}/>
                        </div>
                        <div className="form-group">
                            <label>Contact Phone</label>
                            <input type="tel" name="phone"
                                   className="form-control"
                                   ref={register}/>
                        </div>
                        { !isNew &&
                            <Row>
                                <Col md={4}><ImageUpload label="Avatar" imageTarget="community/avatars" currentImage={c.avatar} targetId={c._id} onUpload={()=>{}} /></Col>
                                <Col md={8}><ImageUpload label="Banner" imageTarget="community/banners" currentImage={c.banner} targetId={c._id} onUpload={()=>{}} /></Col>
                            </Row>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <br/>
                        <div className={`form-group saving-button text-center`}>
                            <i className="fad fa-hurricane fa-fw"></i>
                            <button type={`submit`} className={`btn btn-primary ` + (isSaving ? `disabled` : '')} disabled={isSaving}>{isNew ? 'Save and Continue' : 'Save Changes'}</button>
                            <i className={`fa-fw ` + (isSaving ? `fad fa-hurricane fa-spin active` : saveOk ? 'far fa-check-circle active' : 'fad fa-hurricane')}></i>
                        </div>
                    </Col>
                </Row>
                </form>
            </CardBody>
        </Card>
    );

    // Putting this down here to keep it out of the way
    // Checks if Community Name is unique and if so, returns a unique slug for it
    async function checkCommunityName(name: string) {
        if (name !== c.name) {
            const axiosConfig = { headers: { 'Content-Type': 'application/json' } };
            setCommunityNameOK('loading');
            if (name.length >= 3) {
                try {
                    const res = await axios.get(apiRoot()+'/community/check/name/' + encodeURI(name), axiosConfig);
                    setCommunityNameOK(res.data.result ? 'bad' : 'good');
                    if (res.data.slug) setCommunitySlug(res.data.slug);
                } catch (err) {
                    console.log('Server error on field value check', err);
                }
            }
        } else {
            setCommunityNameOK('good');
            setCommunitySlug(c.slug);
        }
    }
};

CommunityDetailForm.propTypes = {
    getCommunities: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { getCommunities })(CommunityDetailForm);

