import {
    GET_USER_COMMUNITIES,
    CLEAR_USER_COMMUNITIES,
    USER_COMMUNITIES_ERROR,
    SET_ACTIVE_COMMUNITY
} from '../types';

const initialState = {
    communities: [],
    communitiesLoading: true,
    activeCommunity: null,
}

export default function ( state = initialState, action: any) {
    const { type, payload } = action;

    switch(type) {
        case GET_USER_COMMUNITIES:
            return {
                ...state,
                communities: payload,
                communitiesLoading: false,
                activeCommunity: localStorage.activeCommunity ? localStorage.activeCommunity : payload.length ? payload[0]._id : null
            }
        case CLEAR_USER_COMMUNITIES:
            return {
                ...state,
                communities: [],
                communitiesLoading: false
            }
        case SET_ACTIVE_COMMUNITY:
            return {
                ...state,
                activeCommunity: payload
            }
        case USER_COMMUNITIES_ERROR:
            return {
                ...state,
                error: payload,
                communitiesLoading: false,
            }
        default:
            return state;
    }
}