import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { filterEntity } from '../../services/FormService';
import { blankEvent } from '../../shared/models';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Spinner from '../Layout/spinner';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityEvents } from '../../redux/actions/events';
import EventEditForm from './EventEditForm';
import CommunitySwitch from '../Layout/CommunitySwitch';
import Alert from '../Layout/Alert';
import { apiRoot } from '../../services/Helpers';

const EventEdit = (props: any) => {

    const { match, events, activeCommunity, setAlert, removeAlert, getCommunityEvents } = props;
    const [event, setEvent] = useState( blankEvent );
    const isNew = match.path==="/events/new";

    const getEvent = async ( slug: string ) => {
        try {
            const res = await axios( apiRoot()+`/events/slug/${slug}` );
            if (res.data.success) {
                return res.data.payload;
            }
        } catch(err) {
            console.log('event fetch error', err);
        }
    };

    let thisEvent:any = null;

    if (!isNew) {
        useEffect(() => {
            getEvent(match.params.id).then((data) => {
                setEvent(data);
            });
        }, [])
    }

    console.log('new dates', event.location);

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{ isNew ? 'Create' : 'Edit' } Event</h3>
                </Col>
            </Row>
            {isNew &&
            <Row>
              <Col md={12} lg={12}>
                <Alert alertKey="newsEdit" />
                <CommunitySwitch/>
              </Col>
            </Row>
            }
            <Row>
                <Col md={12} lg={12}>
                    { (!isNew && event && event.title && event.title.length) ||
                      (isNew && event) ?
                          <EventEditForm event={event} isNew={isNew} communityId={activeCommunity} redirect={false} />
                            :
                          <Spinner height="400px" />
                        }
                </Col>
            </Row>
        </Container>
    );
};


EventEdit.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityEvents: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
    activeCommunity: state.community.activeCommunity,
    events: state.events.events
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityEvents })(EventEdit);
