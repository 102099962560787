import classNames from 'classnames';
import { Hash, History, LocationState, Search } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router-dom';

import Sidebar, { SidebarField } from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';


interface LayoutProps {
  history: History,
  match: match,
  location: { 
    pathname: string,
    search: Search;
    state: LocationState;
    hash: Hash;
  },
  userData: any,
};
class Layout extends Component<LayoutProps> {

  render() {
    const { userData } = this.props;

    return (
      <div className={`layout`}>
        <Topbar userData={userData} />
        <Sidebar />
      </div>
    );
  }
}

export default withRouter(Layout);
