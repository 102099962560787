import axios from 'axios';

import {GET_USER_COMMUNITIES, SET_ACTIVE_COMMUNITY} from '../types';

import {getCommunityNews} from "./news";
import { apiRoot } from "../../services/Helpers";

// Get the current user's list of communities
export const getCommunities = () => async dispatch => {
  try {
    const res = await axios.get(apiRoot()+'/community/iadmin');
    const activeCommunity = res.data.payload[0]._id;
    dispatch({
      type: GET_USER_COMMUNITIES,
      payload: res.data.payload
    });
    if (localStorage.activeCommunity) {
      dispatch(setActiveCommunity(localStorage.activeCommunity));
    } else {
      dispatch(setActiveCommunity(activeCommunity));
    }
  } catch(err) {
    dispatch({
      type: GET_USER_COMMUNITIES,
      payload: []
    })
  }
};


export const setActiveCommunity = ( cid ) => dispatch => {
  const activeCommunity = cid;
  localStorage.setItem( 'activeCommunity', cid );
  dispatch({
    type: SET_ACTIVE_COMMUNITY,
    payload: activeCommunity
  });
  dispatch(getCommunityNews({
    communityId: activeCommunity,
    page: 1,
    limit: 10
  }))
};
