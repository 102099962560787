import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouterWrapped from './RouterWrapped';
import Login from '../components/Login';
import Register from '../components/UserRegister';
import MainWrapper from '../components/MainWrapper';
import PrivateRoute from './PrivateRoute';
import { removeAlert } from '../redux/actions/alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Router = ( props: any ) => {

    const { removeAlert } = props;

    //removeAlert();

    return (
        <MainWrapper>
            <main>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <PrivateRoute path="/" component={RouterWrapped} />
                </Switch>
            </main>
        </MainWrapper>
    );
}


Router.propTypes = {
    removeAlert: PropTypes.func.isRequired,
};

export default connect(null, { removeAlert })(Router);