import React, { useState } from 'react';
import logo from '../shared/img/logo/commet-logo.svg';
import { Card, CardBody, Col, Container, Row, Button, CardHeader } from "reactstrap";
import { Link, Redirect } from 'react-router-dom';

import { setAlert } from '../redux/actions/alert';
import Alert from '../containers/Layout/Alert';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../redux/actions/auth';


const Login = ( props: any ) => {
    const [formData, setFormData] = useState( { userOrEmail:'', password:''});
    const [showPassword, toggleShowPassword] = useState(false);

    const { userOrEmail, password } = formData;
    const { setAlert, login, auth, isAuthenticated } = props;

    const onChange = (e:any) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e:any) => {
        e.preventDefault();
        login({ userOrEmail, password });
    };

    // Redirect if already signed in
    if (isAuthenticated && auth.user && auth.user.isActive && !auth.user.isBanned) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className="cmt-login">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Commet Logo" className="logo"/>
                                <h3>Community Management Console</h3>
                            </div>
                            <Card>
                                <CardHeader>Sign In</CardHeader>
                                <CardBody>
                                    <Alert alertKey="signinError" />
                                    <form className="auth-form" onSubmit={e => onSubmit(e)}>
                                        <div className={`form-group`}>
                                            <label>Username or Email</label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-user-astronaut"></i></div>
                                                <input
                                                    name="userOrEmail"
                                                    value={userOrEmail}
                                                    type="text"
                                                    onChange={e => onChange(e)}
                                                    className="form-control input-ico-left"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>Password</label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-key"></i></div>
                                                <input
                                                    name="password"
                                                    value={password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={e => onChange(e)}
                                                    minLength={6}
                                                    className="form-control input-ico-left input-ico-right"
                                                    required
                                                />
                                                <div className="ico-right"
                                                     onClick={() => toggleShowPassword(!showPassword)}>
                                                    <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <input type={`submit`} value={`Sign In`} className={`btn btn-primary account__btn`} />
                                        <Link className="btn btn-outline-primary account__btn" to="/register">Create Account</Link>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

Login.propTypes = {
    setAlert: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, login })(Login);



