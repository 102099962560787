export const blankCommunity = {
    name: '',
    slug: '',
    communityType: { name: '' },
    description: '',
    email: '',
    phone: '',
};

export const blankNewsStory = {
    _id: '0',
    title: '',
    subtitle: '',
    slug: '',
    author: '',
    content: '',
    image: 'community/news/default',
    publishDate: new Date().toUTCString(),
    created: new Date().toUTCString(),
    modified: new Date().toUTCString()
}

export const blankEvent = {
    _id: '0',
    title: '',
    subtitle: '',
    slug: '',
    content: '',
    location: {
        type: 'place',
        url: '',
        text: '',
        places: []
    },
    dates: [{
        start: new Date().toISOString(),
        end: new Date().toISOString()
    }],
    image: 'community/news/default',
    created: new Date().toUTCString(),
    modified: new Date().toUTCString()
}

export const blankDeal = {
    _id: '0',
    title: '',
    subtitle: '',
    slug: '',
    content: '',
    location: {
        type: 'place',
        url: '',
        text: '',
        places: []
    },
    image: 'community/news/default',
    dealStarts: new Date().toUTCString(),
    dealEnds: new Date().toUTCString(),
    created: new Date().toUTCString(),
    modified: new Date().toUTCString()
}