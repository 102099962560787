import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { filterEntity } from '../../services/FormService';
import { blankCommunity, blankNewsStory } from '../../shared/models';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Spinner from '../Layout/spinner';
import { removeAlert, setAlert } from '../../redux/actions/alert';
import { getCommunityNews } from '../../redux/actions/news';
import NewsEditForm from './NewsEditForm';
import CommunitySwitch from '../Layout/CommunitySwitch';
import Alert from '../Layout/Alert';
import { apiRoot } from '../../services/Helpers';

const NewsEdit = (props: any) => {

    const { match, stories, activeCommunity, setAlert, removeAlert, getCommunityNews } = props;
    const [story, setStory] = useState( blankNewsStory );
    const isNew = match.path==="/news/new";

    const getStory = async ( slug: string ) => {
        try {
            const res = await axios( apiRoot()+`/news/story/slug/${slug}` );
            if (res.data.success) {
                return res.data.payload;
            }
        } catch(err) {
            console.log('news story fetch error', err);
        }
    };

    let thisStory:any = null;

    if (!isNew) {
        useEffect(() => {
            getStory(match.params.id).then((data) => {
                setStory(data);
            });
        }, [])
    }

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{ isNew ? 'Create' : 'Edit' } News Story</h3>
                </Col>
            </Row>
            {isNew &&
            <Row>
              <Col md={12} lg={12}>
                <Alert alertKey="newsEdit" />
                <CommunitySwitch/>
              </Col>
            </Row>
            }
            <Row>
                <Col md={12} lg={12}>
                    { (!isNew && story && story.title && story.title.length) ||
                      (isNew && story) ?
                          <NewsEditForm story={story} isNew={isNew} communityId={activeCommunity} redirect={false} />
                            :
                          <Spinner height="400px" />
                        }
                </Col>
            </Row>
        </Container>
    );
};


NewsEdit.propTypes = {
    setAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired,
    getCommunityNews: PropTypes.func.isRequired
};

const mapStateToProps = (state: any) => ({
    activeCommunity: state.community.activeCommunity,
    stories: state.news.stories
});

export default connect(mapStateToProps, { setAlert, removeAlert, getCommunityNews })(NewsEdit);
