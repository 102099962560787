import { OPEN_MODAL, CLOSE_MODAL } from '../types';

const initialState:any = {
    open: false,
    modalType: 'normal',
    modalData: {}
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case OPEN_MODAL:
            return {
                open: true,
                modalType: payload.modalType,
                modalData: payload.modalData
            }

        case CLOSE_MODAL:
            return initialState;

        default:
            return state;
    }
}
