import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { mapClass, checkProfileComplete } from '../../services/FormService';
import Spinner from '../Layout/spinner';
import ImageUpload from '../../shared/ImageUpload';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../redux/actions/profile';
import { apiRoot } from '../../services/Helpers';

const MyProfile = (props: any) => {

    const {
        isProfileComplete,
        getCurrentProfile,
        profile: {
            profile,
            loading
        }
    } = props;

    const [aboutMeCt, setAboutMeCt] = useState(0);
    const [usernameOK, setUsernameOK] = useState(-1);
    const [emailOK, setEmailOK] = useState(-1);
    const [displayNames, updateDisplayNames] = useState( [''] );
    const [isSaving, setIsSaving] = useState(false );
    const [saveOk, setSaveOk] = useState(false );

    let origUsername = '';
    let origEmail = '';
    let defaultDisplayNames:any = [];

    if (profile) {
        origUsername = profile.username;
        origEmail = profile.email;

        defaultDisplayNames = [
            profile.username,
            profile.firstName+' '+profile.lastName,
            profile.firstName.substr(0,1)+'. '+profile.lastName,
            profile.firstName+' '+profile.lastName.substr(0,1)+'.'
        ];
    }

    const aboutMeChange = (ev: any) => {
        setAboutMeCt(ev.target.value.length);

        if (ev.target.value.length >= 255) {
            setAboutMeCt(255);
            setValue('aboutMe', ev.target.value.substr(0, 255));
        }
    };

    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            username: profile && profile.username ? profile.username : '',
            email: profile && profile.email ? profile.email : '',
            firstName: profile && profile.firstName ? profile.firstName : '',
            lastName: profile && profile.lastName ? profile.lastName : '',
            displayName: profile && profile.displayName ? profile.displayName : '',
            aboutMe: profile && profile.aboutMe ? profile.aboutMe : '',
            birthDate: profile && profile.birthDate ? profile.birthDate.substr(0,10) : '',
            gender: profile && profile.gender ? profile.gender._id : '',
        }
    });

    const onBlur = (e: any) => {
        const field = e.target.name;
        if (field == 'username' && e.target.value == origUsername) {
            setUsernameOK(-1);
            return true;
        } else if (field == 'email' && e.target.value == origEmail) {
            setEmailOK(-1);
            return true;
        } else if (e.target.value.length >= 4) {
            if (field == 'username') {
                setUsernameOK(0);
            } else {
                setEmailOK(0);
            }
            checkUserValue(field, e.target.value);
        }
        return true;

    };

    const onSubmit = async (data: any) => {
        console.log(data);
        setIsSaving(true);
        try {
            const res = await axios.post(apiRoot()+'/profile/', data);
            if (res.data.success) {
                console.log('profile updated!');
                getCurrentProfile();
            }

        } catch(err) {
            console.log('API Error while saving profile', err);
        }
        setIsSaving(false);
        setSaveOk(true);
        setTimeout(function(){ setSaveOk(false); },5000);
    };

    const checkUserValue = async (field: string, value: string) => {
        try {
            if (field && value.length >= 4) {
                const res = await axios.get(apiRoot()+'/auth/check/' + field + '/' + value);
                if (res.data.result === false) {
                    if (field == 'username') { setUsernameOK(1); }
                    else { setEmailOK(1); }
                } else {
                    if (field == 'username') { setUsernameOK(2); }
                    else { setEmailOK(2); }
                }
            }
        } catch (err) {
            console.log('Server error on field value check', err);
            if (field == 'username') { setUsernameOK(2); }
            else { setEmailOK(2); }
        }
    };

    const profileComplete = !loading && profile ? checkProfileComplete(profile) : false;

    return loading && profile === null ? <Spinner/> :
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">My Profile</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            {!profileComplete &&
                            <div className="alert--bordered alert alert-danger alert-bottom-margin">
                              <div className="alert__icon"><i className={`far fa-times-circle`}></i></div>
                              <div className="alert__content"><strong>Please complete your user profile:</strong>
                                <div>There are a few required fields you must complete before continuing.</div>
                              </div>
                            </div>
                            }
                            <div className="auth-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm={2}>
                                        <ImageUpload label="Profile Image" imageTarget="user/avatars"
                                                     currentImage={(profile && profile.avatar) && profile.avatar}
                                                     targetId={(profile && profile._id) && profile._id}
                                        />
                                    </Col>

                                    <Col sm={5}>
                                        <div className={`form-group`}>
                                            <label className="req">
                                                {errors.username ? <span>Username Is Invalid</span> : usernameOK == 2 &&
                                                  <span>Username Already In Use</span>}
                                                Username
                                            </label>
                                            <div className="auth-form-group">
                                                <input
                                                    name="username"
                                                    type="text"
                                                    onChange={e => onBlur(e)}
                                                    onBlur={e => onBlur(e)}
                                                    className={`form-control ` + mapClass(usernameOK, !!errors.username)}
                                                    ref={register({ required: true, minLength: 4, maxLength: 24 })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="req">{errors.firstName && <span>Required</span>}First Name</label>
                                            <input type="text" name="firstName" className={`form-control`}
                                                   ref={register({ required: true })}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="req">{errors.lastName && <span>Required</span>}Last Name</label>
                                            <input type="text" name="lastName" className={`form-control`}
                                                   ref={register({ required: true })}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="req">Display Name Publicly As...</label>
                                            <select name="displayName" className="form-control"
                                                    ref={register({ required: true })}>
                                                {defaultDisplayNames.map((name:string) =>
                                                    <option key={name} value={name}>{name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <div className={`form-group`}>
                                            <label className="req">
                                                {errors.email ? <span>Email Is Invalid</span> : emailOK == 2 &&
                                                  <span>Email Already In Use</span>}
                                                Email Address
                                            </label>
                                            <div className="auth-form-group">
                                                <input
                                                    name="email"
                                                    type="text"
                                                    onChange={e => onBlur(e)}
                                                    onBlur={e => onBlur(e)}
                                                    className={`form-control ` + mapClass(emailOK, !!errors.email)}
                                                    ref={register({
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="req">{errors.birthDate && <span>Required</span>}Birth Date</label>
                                            <input type="date" name="birthDate" className={`form-control`}
                                                   ref={register({ required: true })}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="req">{errors.gender && <span>Required</span>}Gender</label>
                                            <select name="gender" className="form-control"
                                                    ref={register({ required: true, pattern: /[A-Za-z0-9]{24}/ })}>
                                                <option></option>
                                                <option value="5e13e35a1c9d4400005109fc">Female</option>
                                                <option value="5e13e38d1c9d4400005109fd">Male</option>
                                                <option value="5e13e3961c9d4400005109fe">Trans Female</option>
                                                <option value="5e13e3a21c9d4400005109ff">Trans Male</option>
                                                <option value="5e13e3ad1c9d440000510a00">Agender</option>
                                                <option value="5e13e3b71c9d440000510a01">Bigender</option>
                                                <option value="5e13e3c61c9d440000510a02">Intersex</option>
                                                <option value="5e13e3d11c9d440000510a03">Non-Binary</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>About Me <span
                                                className={(aboutMeCt < 235 ? "gray" : "")}>{(255 - aboutMeCt)} Characters Remaining</span></label>
                                            <textarea className="form-control aboutme" name="aboutMe"
                                                      onChange={(e) => aboutMeChange(e)}
                                                      ref={register({ maxLength: 255 })}></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <br/>
                                        <div className={`form-group saving-button`}>
                                            <i className="fad fa-hurricane fa-fw"></i>
                                            <button type={`submit`} className={`btn btn-primary ` + (isSaving ? `disabled` : '')} disabled={isSaving}>Save Profile</button>
                                            <i className={`fa-fw ` + (isSaving ? `fad fa-hurricane fa-spin active` : saveOk ? 'far fa-check-circle active' : 'fad fa-hurricane')}></i>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
};

MyProfile.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
};
const mapStateToProps = (state: any) => ({
    profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile })(MyProfile);
