import { LocationDescriptor } from 'history';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';

interface SidebarLinkProps {
  title: string,
  icon?: string,
  newLink?: boolean,
  route?: LocationDescriptor<any>,
  onClick?: () => void,
};

const SidebarLink: FunctionComponent<SidebarLinkProps> = ({
  title, icon = '', newLink = false, route = '/', onClick = () => {},
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <i className={`fad fa-fw fa-${icon}`}></i> : ''}
      <p className="sidebar__link-title">
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

export default SidebarLink;
