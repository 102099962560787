import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

interface MainWrapperProps {
  theme?: any;
  children: any;
}
class MainWrapper extends PureComponent<MainWrapperProps> {

  render() {
    const { theme, children } = this.props;

    return (
      <div className="theme-light ltr-support">
        <div className="wrapper">
          {children}
        </div>
      </div>
    );
  }
}

export default MainWrapper;