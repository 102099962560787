import React, { PureComponent, Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';


export default class Billing extends Component {

    render() {
        return (
            <Container className="dashboard">
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Dashboard</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}