import {
    GET_COMMUNITY_EVENTS,
    CLEAR_COMMUNITY_EVENTS,
    COMMUNITY_EVENTS_LOADING,
    SET_ACTIVE_EVENT
} from "../types";

const initialState = {
    events: null,
    eventsLoading: true,
    limit: 10,
    page: 1,
    total: 0,
    activeEvent: null,
}

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case GET_COMMUNITY_EVENTS:
            return {
                ...state,
                eventsLoading: false,
                events: payload.docs,
                page: payload.page > payload.totalPages ? payload.totalPages : payload.page,
                total: payload.totalDocs,
                limit: payload.limit,
            }
        case CLEAR_COMMUNITY_EVENTS:
            return {
                ...state,
                eventsLoading: false,
                stories: []
            }
        case COMMUNITY_EVENTS_LOADING:
            return {
                ...state,
                eventsLoading: payload,
            }
        case SET_ACTIVE_EVENT:
            return {
                ...state,
               activeEvent: payload,
            }
        default:
            return state;
    }


}